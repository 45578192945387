import r2wc from '@r2wc/react-to-web-component'
import GoogleReviewWCWrapper from './../components/NameCard/GoogleReview/GoogleReviewWCWrapper'

// customElements?.define(
//   'my-web-component1',
//   r2wc(MyComponent, {
//     props: ['message']
//   })
// )

// customElements?.define(
//   'my-web-component2',
//   r2wc(MyComponent2, {
//     props: ['message']
//   })
// )

customElements?.define(
  'ovb-google-review',
  r2wc(GoogleReviewWCWrapper, {
    props: [
      'office',
      'includeoffice',
      'userovbid',
      'userid',
      'includetitle',
      'basecolorhex',
      'slidercolumns'
    ]
  })
)
