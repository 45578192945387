const hu = {
  Author: 'Szerző',
  'Add new': 'Hozzáadás',
  'All rights reserved': 'Minden jog fenntartva.',
  'Are you sure of deleting this object?':
    'Biztosan törölni szeretnéd ezt az elemet?',
  Created: 'Létrehozva',
  Yes: 'Igen',
  Time: 'Idő',
  No: 'Nem',
  Back: 'Vissza',
  Categories: 'Kategóriák',
  Confirmation: 'Megerősítés',
  Country: 'Ország',
  Dashboard: 'Irányítópult',
  Delete: 'Törlés',
  Description: 'Leírás',
  DRAFT: 'Vázlat',
  Edit: 'Szerkesztés',
  Email: 'Email',
  Filter: 'Szűrés',
  Firstname: 'Keresztnév',
  Id: 'Id',
  'Is Active': 'Aktív-e',
  Lastname: 'Vezetéknév',
  Login: 'Bejelentkezés',
  Name: 'Név',
  'Ovb Id': 'Ovb Id',
  Page: 'Oldalszám',
  'Page size': 'Találatok száma',
  Phone: 'Telefon',
  Password: 'Jelszó',
  PUBLISH: 'Publikált',
  ARCHIVE: 'Archív',
  Restore: 'Visszállitás',
  REVIEW: 'Jóváhagyásra vár',
  Status: 'Státusz',
  Submit: 'Küldés',
  'Submit and back': 'Küldés és vissza',
  System: 'Rendszer',
  Tags: 'Címkék',
  'Tax Number': 'Adószám',
  Title: 'Cím',
  'User Level': 'Rang',
  Username: 'Felhasználónév',
  Versions: 'Verziók',
  'Table configuration': 'Táblázat beállítások',
  Close: 'Bezárás',
  Opening: 'Nyitás',
  Clear: 'Törlés',
  Search: 'Keresés',
  'Searching...': 'Keresés...',
  'Items per page': 'Elemek oldalanként',
  item: 'elem',
  State: 'Státusz',
  'Email address': 'Email cím',
  'Forgot password?': 'Elfelejtett jelszó?',
  Cancel: 'Mégse',
  Visibility: 'Láthatóság',
  'Review note': 'Megjegyés a jóváhagyáshoz',
  History: 'Előzmények',
  Unpublish: 'Publikálás visszavonása',
  'Save as draft': 'Beállítások mentése',
  'Submit for review': 'Jóváhagyásra küldés',
  Publish: 'Publikálás',
  Reject: 'Elutasítás',
  'New draft': 'Új vázlat',
  'This version is in published state. To make changes, create a new version.':
    'Ez a verzió publikált státuszban van. A módosításához hozz létre egy új verziót.',
  'This version is in archived state. To make changes, create a new version.':
    'Ez a verzió archivált státuszban van. A módosításához hozz létre egy új verziót.',
  Active: 'Aktív',
  Inactive: 'Inaktív',
  'Discard draft': 'Vázlat elvetése',
  'Are you sure of restoring this version?':
    'Biztosan vissza szeretnéd állítani ezt a verziót?',
  'Are you sure of unpublishing this version?':
    'Biztosan vissza szerednéd vonni a publikálását ennek a verziónak?',
  Discard: 'Elvet',
  'Are you sure of discarding this draft?':
    'Biztosan el szeretnéd vetni ezt a vázlatot?',
  'Delete permanently': 'Végleges törlés',
  "Are you sure of permanently deleting this item with all it's versions?":
    'Biztosan véglegesen törölni szeretnéd ezt az elemet az összes verziójával együtt?',
  Save: 'Mentés',
  'Save and back': 'Mentés és vissza',
  'Save and next': 'Mentés és következő',
  Change: 'Módosítás',
  'New photo': 'Új kép',
  'This video is being processed': 'A videó feldolgozás alatt áll',
  'This video has no available version': 'A videónak nincs elérhető verziója',
  'Related articles': 'Kapcsolódó cikkek',
  'Name length must be between 3 and 50.':
    'A név hossza 3 és 50 karakter között kell legyen.',
  'Click to view the gallery': 'Kattints a galéria megtekintéséhez',
  Creator: 'Létrehozó',
  Category: 'Kategória',
  Assignee: 'Felelős',
  Priority: 'Prioritás',
  Summary: 'Összefoglaló',
  Tickets: 'Ticketek',
  'New ticket': 'Új ticket indítása',
  Level: 'Szint',
  open: 'nyitott',
  'in-review': 'visszajelzésre vár',
  closed: 'Lezárva',
  'Not set': 'Nincs megadva',
  Attachments: 'Csatolmányok',
  Comments: 'Hozzászólások',
  'Manager comments': 'Menedzser hozzászólások',
  Details: 'Részletek',
  Comment: 'Hozzászólás',
  'Sending messages to central colleagues': 'Üzenetküldés központi kollégáknak',
  Assign: 'Átruházás',
  'Type a message...': 'Írj egy üzenetet...',
  priority: 'prioritás',
  status: 'státusz',
  category: 'kategória',
  Owner: 'Tulajdonos',
  Users: 'Felhasználók',
  Articles: 'Cikkek',
  More: 'Több',
  'Search...': 'Keresés...',
  Module: 'Modul',
  Modules: 'Modulok',
  Take: 'Én leszek a felelős',
  Variables: 'Változók',
  Export: 'Exportálás',
  'Something went wrong.': 'Valami rosszul sült el.',
  'Report feedback': 'Visszajelzés küldése',
  Actual: 'Aktuális',
  Now: 'Most',
  Subscribers: 'Feliratkozók',
  Subscribe: 'Feliratkozás',
  Unsubscribe: 'Leiratkozás',
  Invite: 'Meghívás',
  'Event Key': 'Esemény kulcs',
  Template: 'Sablon',
  'Notification preferences': 'Értesítés beállítások',
  Depth: 'Mélység',
  contact: 'kapcsolattartó',
  Changes: 'Változások',
  'Edit changes': 'Változások módosítása',
  Preview: 'Előnézet',
  Preferences: 'Beállítások',
  Groups: 'Csoportok',
  Group: 'Csoport',
  Subgroup: 'Alcsoport',
  Subgroups: 'Alcsoportok',
  Table: 'Táblázat',
  Tree: 'Fa',
  Accepted: 'Elfogadva',
  'My open tickets': 'Nyitott ticketeim',
  Resolved: 'Megoldva',
  All: 'Összes',
  'Order business cards': 'Névjegykártya rendelés',
  'New order': 'Új rendelés',
  Date: 'Dátum',
  'To-do': 'Teendő',
  Print: 'Nyomtatás',
  Days: 'Napok',
  'Solution rate': 'Megoldási ráta',
  'In Progress': 'Folyamatban',
  Closed: 'Lezárva',
  'Without assignee': 'Felelős nélkül',
  Assigned: 'Kiosztva',
  'Prepare new order': 'Új rendelés összekészítése',
  'Business card orders': 'Névjegykártya rendelések',
  User: 'Felhasználó',
  Note: 'Megjegyzés',
  Request: 'Igénylés',
  'Expire date': 'Lejárati dátum',
  Products: 'Termékek',
  Requirements: 'Követelmények',
  Profile: 'Profil',
  Logout: 'Kijelentkezés',
  'Click here to search...': 'Kattintson ide a kereséshez...',
  'Request broker license': 'Banki kód igénylése',
  Partner: 'Partner',
  Modified: 'Módosítva',
  'My Broker License': 'Banki kódom',
  'Partner excel import': 'Partner excel importálás',
  Product: 'Termék',
  'Original Product': 'Eredeti termék',
  'Valid until': 'Érvényes',
  'You can generate this document with the following form':
    'A következő űrlappal állíthatod elő ezt a dokumentumot',
  Documents: 'Dokumentumok',
  'Terms and Conditions': 'Általános felhasználási feltételek',
  'Assignment Statement': 'Megbízási nyilatkozat',
  Annexes: 'További csatolmányok',
  'Send to review': 'Jóváhagyásra küldés',
  Key: 'Kulcs',
  Next: 'Tovább',
  'Interactive block completed': 'Interaktív blokk véget ért',
  Timer: 'Időzítő',
  Seconds: 'Másodpercek',
  'Show block': 'Blokk mutatása',
  'Hide block': 'Blokk elrejtése',
  'Scroll to': 'Görgetés',
  "Set next buttons' text": 'Következő gomb szövegének állítása',
  Exit: 'Kilépés',
  'Exit presentation': 'Kilépés a prezentációból',
  'Waiting for next action': 'Várakozás a következő lépésre',
  Navigate: 'Navigálás',
  Block: 'Blokk',
  'Blocks key': 'Blokk kulcsa',
  Slide: 'Fólia',
  'Slides key': 'Fólia kulcsa',
  'Are you sure of publishing this version?': 'Biztosan publikálod a verziót?',
  'Choose a category!': 'Válassz egy kategóriát!',
  'Choose related category!': 'Válassz kapcsolódó kategóriát!',
  FAQ: 'GYIK',
  'I found my answers': 'Választ kaptam a kérdésemre',
  'Create new ticket': 'Új ticket létrehozása',
  Information: 'Információ',
  Contents: 'Tartalmak',
  'It looks like we’re having issues.': 'Úgy tűnik valami rosszul sült el.',
  'Our team has been notified.': 'A fejlesztő kollégák értesítve lesznek.',
  'If you’d like to help, tell us what happened below.':
    'Ha szeretnél segíteni, írd le mi történt.',
  'What happened?': 'Mi történt?',
  'An unknown error occurred while submitting your report. Please try again.':
    'Egy ismeretlen hiba történt a visszajelzés beküldése során. Probáld újra.',
  'Some fields were invalid. Please correct the errors and try again.':
    'Néhány mező helytelen. Kérlek javítsd a hibákat és próbáld újra.',
  'Your feedback has been sent. Thank you!':
    'A visszajelzés rögzítve lett. Köszönjük a segítséget!',
  Expand: 'Kinyit',
  'Order fulfilled': 'A megrendelés teljesült',
  Channel: 'Csatorna',
  'Mobile Phone Number': 'Mobiltelefonszám',
  'Work Email': 'Munkahelyi e-mail',
  'Time machine': 'Időgép',
  'Featured on Dashboard': 'Kiemelés főoldalon',
  'Featured in Article List': 'Kiemelés cikklistában',
  'You are viewing an older version of this article.':
    'A cikk egy korábbi verzióját nézed.',
  'Switch to current version': 'Váltás a jelenlegi verzióra',
  Published: 'Publikálva',
  'Integration Type': 'Integráció típusa',
  Url: 'Url',
  Tiles: 'Csempék',
  Refresh: 'Frissítés',
  'Customize dashboard': 'Irányítópult testreszabása',
  'View as...': 'Megtekintés mint...',
  Clients: 'Ügyfelek',
  Introduction: 'Bemutatkozás',
  'Starter comment': 'Indító hozzászólás',
  'See More': 'Tovább',
  'Choose a conversation type': 'Válassz egy beszélgetés típust',
  'Choose a user': 'Válassz egy felhasználót',
  'Conversation Type': 'Beszélgetés típus',
  'Write something...': 'Írj valamit...',
  'Start new PG': 'Új PG indítása',
  Notes: 'Jegyzetek',
  'Conversation points': 'Beszélgetési pontok',
  Commitments: 'Vállalások',
  Dashboards: 'Irányítópultok',
  discussed: 'megbeszélve',
  pending: 'függőben',
  commitment: 'vállalás',
  postponement: 'elhalasztás',
  'Preparation content': 'Felkészülési tartalom',
  'Leader content': 'Vezetői tartalom',
  'Employee content': 'Munkatársi tartalom',
  Assessment: 'Értékelés',
  Saving: 'Mentés',
  Saved: 'Mentve',
  reset: 'visszaállítás',
  Undo: 'Visszavon',
  'no commitments': 'nincs vállalás',
  'Other commitment': 'Egyéb vállalás',
  'Other commitments': 'Egyéb vállalások',
  'Without deadline': 'Határidő nélkül',
  'Missing title': 'Hiányzó cím',
  accordion: 'harmonika',
  attachment: 'csatolmány',
  audio: 'audio',
  chart: 'grafikon',
  gallery: 'galéria',
  grid: 'rács',
  header: 'fejléc',
  subheader: 'alfejléc',
  image: 'kép',
  list: 'lista',
  paragraph: 'bekezdés',
  sectionbreak: 'szakasztörés',
  table: 'táblázat',
  video: 'videó',
  'Entrance animation': 'Belépés animációja',
  'Exit animation': 'Kilépés animációja',
  'Hide fast': 'Gyors elrejtés',
  'Back in down': 'Hátulról le',
  'Back in left': 'Hátulról balra',
  'Back in right': 'Hátulról jobbra',
  'Back in up': 'Hátulról fel',
  'Bounce in': 'Beugrás',
  'Bounce in down': 'Beugrás alulról',
  'Bounce in left': 'Beugrás balról',
  'Bounce in right': 'Beugrás jobbról',
  'Bounce in up': 'Beugrás fentről',
  'Fade in': 'Előtűnés',
  'Fade in down': 'Előtűnés alulról',
  'Fade in down big': 'Előtűnés alulról nagyban',
  'Fade in left': 'Előtűnés balról',
  'Fade in left big': 'Előtűnés balról nagyban',
  'Fade in right': 'Előtűnés jobbról',
  'Fade in right big': 'Előtűnés jobbról nagyban',
  'Fade in up': 'Előtűnés fentről',
  'Fade in up big': 'Előtűnés fentről nagyban',
  'Fade in top left': 'Előtűnés fent balról',
  'Fade in top right': 'Előtűnés fent jobbról',
  'Fade in bottom left': 'Előtűnés alul balról',
  'Fade in bottom right': 'Előtűnés alul jobbról',
  'Flip in X': 'Befordulás X tengelyen',
  'Flip in Y': 'Befordulás Y tengelyen',
  'Lightspeed in right': 'Gyorsulva be jobbról',
  'Lightspeed in left': 'Gyorsulva be balról',
  'Rotate in': 'Beforgás',
  'Rotate in down left': 'Beforgás alul balról',
  'Rotate in down right': 'Beforgás alul jobbról',
  'Rotate in up left': 'Beforgás fent balról',
  'Rotate in up right': 'Beforgás fent jobbról',
  'Roll in': 'Begördülés',
  'Zoom in': 'Bezoomolás',
  'Zoom in down': 'Bazoomolás alulról',
  'Zoom in left': 'Bezoomolás balról',
  'Zoom in right': 'Bezoomolás jobbról',
  'Zoom in up': 'Bezoomolás fentről',
  'Slide in down': 'Becsúszás alulról',
  'Slide in left': 'Becsúszás balról',
  'Slide in right': 'Becsúszás jobbról',
  'Slide in up': 'Becsúszás fentről',
  'Back out down': 'Kihátrálás alul',
  'Back out left': 'Kihátrálás balra',
  'Back out right': 'Kihátrálás jobbra',
  'Back out up': 'Kihátrálás fent',
  'Bounce out': 'Kiugrás',
  'Bounce out down': 'Kiugrás alul',
  'Bounce out left': 'Kiugrás balra',
  'Bounce out right': 'Kiugrás jobbra',
  'Bounce out up': 'Kiugrás fent',
  'Fade out': 'Eltűnés',
  'Fade out down': 'Eltűnés alul',
  'Fade out down big': 'Eltűnés alul nagyban',
  'Fade out left': 'Eltűnés balra',
  'Fade out left big': 'Eltűnés balra nagyban',
  'Fade out right': 'Eltűnés jobbra',
  'Fade out right big': 'Eltűnés jobbra nagyban',
  'Fade out up': 'Eltűnés fent',
  'Fade out up big': 'Eltűnés fent nagyban',
  'Fade out top left': 'Eltűnés balra fent',
  'Fade out top right': 'Eltűnés jobbra fent',
  'Fade out bottom right': 'Eltűnés jobbra alul',
  'Fade out bottom left': 'Eltűnés balra alul',
  'Flip out X': 'Kifordulás X tengelyen',
  'Flip out Y': 'Kifordulás Y tengelyen',
  'Lightspeed out right': 'Gyorsulva ki jobbra',
  'Lightspeed out left': 'Gyorsulva ki balra',
  'Rotate out': 'Kiforgás',
  'Rotate out down left': 'Kiforgás alul balra',
  'Rotate out down right': 'Kiforgás alul jobbra',
  'Rotate out up left': 'Kiforgás fent balra',
  'Rotate out up right': 'Kiforgás alul jobbra',
  'Roll out': 'Kigördülés',
  'Zoom out': 'Kizoomolás',
  'Zoom out down': 'Kizoomolás alul',
  'Zoom out left': 'Kizoomolás balra',
  'Zoom out right': 'Kizoomolás jobbra',
  'Zoom out up': 'Kizoomolás fent',
  'Slide out down': 'Kicsúszás alul',
  'Slide out left': 'Kicsúszás balra',
  'Slide out right': 'Kicsúszás jobbra',
  'Slide out up': 'Kicsúszás fent',
  'employee task': 'munkatársi feladat',
  'employee task with verification': 'munkatársi feladat megerősítéssel',
  'employee task with leaders verification':
    'munkatársi feladat vezetői megerősítéssel',
  'leader task with leaders verification':
    'vezetői feladat vezetői megerősítéssel',
  Deadline: 'Határidő',
  'Starter description': 'Kezdő leírás',
  Type: 'Típus',
  'Production Period': 'Termelési időszak',
  'Is Closed': 'Lezárt',
  'Production Date Beginning': 'Termelés kezdete',
  'Production Date Ending': 'Termelés vége',
  'Period Category': 'Tervezés kategória',
  'From Period': 'Kezdő periódus',
  'To Period': 'Záró periódus',
  Day: 'Nap',
  'Day Type': 'Nap típusa',
  'Reference Type': 'Referencia típusa',
  'Event Type': 'Esemény típusa',
  'Start Date': 'Kezdő Dátum',
  Participants: 'Résztvevők',
  multiplier: 'szorzó',
  'data collection': 'adatgyűjtés',
  'Planning Period': 'Tervezési Ciklus',
  'No planning': 'Nincs tervezés',
  "Use previous period's values": 'Előző ciklus értékeinek használata',
  'Base data': 'Alapadat',
  'Multiplier should be between {min} and {max}.':
    'A szorzó {min} és {max} között érdemes maradjon.',
  Duplicate: 'Duplikálás',
  'Duplicate Node': 'Költségefa másolása',
  Copy: 'Másolat',
  'Ticket categories by days elapsed until resolution':
    'Ticket kategóriák a lezárásig eltelt napok bontásában',
  Open: 'Nyitott',
  'Ticket stats': 'Ticket statisztikák',
  Communication: 'Kommunikáció',
  Duration: 'Idő',
  Professionality: 'Professzionalitás',
  Average: 'Átlag',
  'Resend code': 'Kód újraküldése',
  'Trusted device': 'Megbízható eszköz',
  'Accept articles': 'Cikkek elfogadása',
  'No articles to accept.': 'Nincs elfogadásra váró cikk.',
  'Webshop Order': 'Megrendelés',
  Order: 'sorrend',
  'Total price': 'Teljes ár',
  Total: 'Összesen',
  'Ordered Item Count': 'Rendelt termék Db',
  Editable: 'Szerkeszthető',
  'Order date': 'Rendelés időpontja',
  'Product count': 'Termék mennyiség',
  Available: 'Elérhető',
  'Add to cart': 'Kosárba rakás',
  'Unit price': 'Egységár',
  'Price total': 'Ár összesen',
  Quantity: 'Darab',
  Sucess: 'Siker',
  'Order successfully sent': 'Sikeres megrendelés',
  'no deadline': 'nincs határidő',
  'Add other commitment': 'Egyéb vállalás felvétele',
  'No available content': 'Nincs elérhető tartalom',
  'No results': 'Nincs találat',
  'New version available, please reload the portal.':
    'Új verzió érhető el, frissítsd a portált.',
  Reload: 'Újratöltés',
  'Audio records': 'Hangfelvételek',
  'To start recording click the mic.':
    'A felvétel indításához kattints a mikrofonra.',
  'Previous assessments': 'Korábbi értékelések',
  'Statistics of this product': 'A termék statisztikái',
  'Product arrived': 'Termék beérkezés',
  'Product sold': 'Termék kiadás',
  'Shortage of product': 'Termék hiány',
  'Central usage': 'Központi felhasználás',
  'Active orders': 'Aktív rendelések',
  'Transaction Type': 'Tranzakció Típusa',
  'Out of stock': 'Nincs készleten',
  'Almost sold out': 'Már nem sok van',
  'In stock': 'Készleten',
  'Start typing to search...': 'Kezdj el írni a kereséshez...',
  'General purpose': 'Szabad felhasználású',
  'Second hand apartment': 'Használt lakás',
  'House extension': 'Bővítés',
  Building: 'Építés',
  'New apartment': 'Új lakás',
  Modernization: 'Korszerűsítés',
  Renovation: 'Felújítás',
  'Used apartment': 'Használt lakás',
  'Loan refinancing (residential)': 'Hitelkiváltás (lakáscélú)',
  Restoration: 'Felújítás',
  'Free use': 'Szabad felhasználás',
  'Credit purpose': 'Hitelcél',
  'Select all': 'Összes kijelölése',
  'Loan amount': 'Hitel összege',
  'Original interest rate': 'Eredeti kamat',
  'Reduced interest rate': 'Egyedi kamat',
  'Total financial advantage': 'Teljes pénzügyi előny',
  'Monthly financial advantage': 'Havi pénzügyi előny',
  'Both members of the couple have a Hungarian address':
    'Mindkét félnek van magyar lakcíme',
  'Does either member of the couple have NAV debt':
    'Van valamelyik félnek köztartozása',
  'Does either member of the couple have social insurance':
    'Legalább az egyik fél rendelkezik megfelelő TB jogviszonnyal',
  "Husband's birth date": 'Férj születési dátuma',
  "Wife's birth date": 'Feleség születési dátuma',
  'Has social insurance for more than 180 days':
    'A TB jogviszony utolsó 180 napja magyar TB-ből származik',
  'KHR status': 'Az igénylők KHR státusza',
  'Refinanced debt': 'Kiváltandó hitelek fennálló tőketartozása',
  'Refinanced installment': 'Kiváltandó hitelek fennálló törlesztőrészlete',
  'Remaining debt': 'Megmaradó hitelek fennálló tőketartozása',
  'Remaining installment': 'Megmaradó hitelek fennálló törlesztőrészlete',
  'Total debt': 'Összes fennálló tartozás',
  'Total installment': 'Összes törlesztőrészlet',
  'Total debts of the participants':
    'Az igénylők összes fennálló hiteltartozása',
  'Total installments of the participants':
    'Az igénylők összes havi törlesztőrészlete',
  'Calculated loan amount': 'Kalkulált hitelösszeg',
  'Loan amount': 'Igényelhető összeg',
  'Min duration': 'Minimális futamidő',
  "Husband's Minimum Age": 'Férj minimum életkora az igényléskor',
  'Mandatory account': 'Kötelező a banknál bankszámlát vezetni',
  "Wife's Minimum Age": 'Feleség minimum életkora az igényléskor',
  'New Loan amount': 'Új hitelösszeg',
  'Maximal redemption': 'Maximális vállalható havi törlesztés',
  Married: 'Házas',
  Term: 'Futamidő',
  'Property value': 'Ingatlan értéke',
  'Revenue (Debtor 1 income)': 'Bevételek (Adós 1 jövedelme)',
  'Revenue (Debtor 2 income)': 'Bevételek (Adós 2 jövedelme)',
  "Use this field only if Debtor 2 has at least 1 minimum wage from independently acceptable income! If Debtor 2 has only additional income, enter Debtor 2's income for Debtor 1 as well!":
    'Kizárólag akkor használd ezt a mezőt, ha Adós 2 rendelkezik legalább 1 önállóan elfogadható jövedelemből származó minimálbérrel! Amennyiben Adós 2-nek csak kiegészítő jövedelme van, akkor Adós 1-hez írd be Adós 2 jövedelmeit is!',
  'Market value of the property used as collateral for the loan.':
    'A hitel fedezetéül szolgáló ingatlan piaci értéke.',
  'You will repay the loan in that amount of time.':
    'Ennyi időn keresztül fogja visszafizetni a hitelt.',
  'How much credit do you want to take out?':
    'Mekkora hitelt szeretne felvenni?',
  'What do you want to borrow for?': 'Milyen célra szeretné felvenni a hitelt?',
  Year: 'Év',
  'Only show banks where CSOK can be claimed':
    'Csak azok a bankok megjelenítése, ahol igényelhető a CSOK',
  'Show CSOK loans only': 'Csak a CSOK hitelek megjelenítése',
  'I just want to see Home Renovation Loans':
    'Csak az Otthonfelújítási kölcsönöket szeretném látni',
  'I only want to see Qualified Consumer Friendly Morgtages':
    'Csak a Minősített Fogyasztóbarát Jelzáloghiteleket szeretném látni',
  'I would also like to see discounts for public sector employees':
    'A közszféra munkavállalóinak nyújtott kedvezményeket is szeretném látni',
  'Home insurance': 'Lakásbiztosítás',
  'Credit insurance': 'Hitelfedezeti biztosítás',
  'Active account usage': 'Aktív számlahasználat',
  'Account management': 'Számlavezetés',
  'Active card use': 'Aktív kártyahasználat',
  'Credit card purchases per month in the amount expected by the bank.':
    'Bankkártyás vásárlás havonta a bank által elvárt összegben.',
  'Maintaining the account package defined by the bank.':
    'A bank által meghatározott számlacsomag fenntartása.',
  'A direct debit order or other expected transactions are executed on the loan repayment account.':
    'A kölcsön törlesztési számláján csoportos beszedési megbízás vagy egyéb elvárt tranzakciók teljesülnek.',
  'Conclusion of home insurance specified by the bank.':
    'A bank által meghatározott lakásbiztosítás megkötése.',
  'for 3 months': '3 hónapig',
  'for 6 months': '6 hónapig',
  'for 1 year': '1 évig',
  'for 3 years': '3 évig',
  'for 5 years': '5 évig',
  'for 10 years': '10 évig',
  'for 15 years': '15 évig',
  'for 20 years': '20 évig',
  'Fixed till the end': 'Végig fix',
  'Family monthly income': 'Család havi jövedelme',
  Revenues: 'Bevételek',
  'Revenues:': 'Bevételek:',
  Age: 'Életkor',
  'Number of children': 'Gyermekek száma',
  Person: 'fő',
  'When calculating a home renovation loan, enter only the number of existing children.':
    'Otthonfelújítási kölcsön kalkulálása esetén csak a meglévő gyermekek számát írd be.',
  'The amount remaining in your checking account at the end of the month after your loan is repaid and your other monthly expenses are paid.':
    'A hiteltörlesztése és az egyéb havi kiadásainak kifizetését követően a hónap végén folyószámláján maradó összeg mértéke.',
  'Property location': 'Ingatlan elhelyezkedése',
  'In the case of some state-subsidized loans, you can take out a larger amount of loans in Budapest and cities with county status':
    'Egyes államilag támogatott hitelek esetén nagyobb összegű hitelt vehet fel Budapesten és megyei jogú városokban',
  'Existing loan repayments': 'Meglévő hiteltörlesztések',
  'The amount of the installments on outstanding loans (e.g., mortgage, personal loan, etc.).':
    'A fennálló hitelek (pl.: jelzáloghitel, személyi kölcsön, stb.) törlesztőrészleteinek az összege.',
  'Existing credit line': 'Meglévő hitelkeret',
  'The total amount of credit cards and overdrafts (not just used).':
    'A hitelkártya és folyószámlahitel hitelkereteinek teljes összege (nem csak a kihasznált).',
  'Unspent amount per month': 'Havi el nem költött összeg',
  'Customer rating': 'Ügyfélminősítés',
  'Credit type': 'Hiteltípus',
  'Other settings': 'Egyéb beállítások',
  'For a better loan, you agree to the following':
    'Kedvezőbb hitelért vállalja a következőket',
  'Fixed installment': 'Fix törlesztőrészlet',
  Banks: 'Bankok',
  'The minimum loan amount is HUF 1 000 000!':
    'A minimálisan felvehető hitelösszeg 1 000 000 Ft!',
  'The minimum value of the property you want to buy is HUF 1 250 000!':
    'A vásárolni kívánt ingatlan minimális értéke 1 250 000 Ft!',
  'An underage cannot take out a loan!':
    'Kiskorú személy nem vehet fel hitelt!',
  'The maximum number of children that can be entered is 10!':
    'A maximálisan megadható gyermekszám 10!',
  'The minimum installment that can be given is HUF 3 500!':
    'A minimális megadható törlesztőrészlet 3 500 Ft!',
  'The minimum credit line that can be specified is HUF 3 500!':
    'A minimális megadható hitelkeret 3 500 Ft!',
  'The minimum monthly saving can be HUF 3 500!':
    'A minimális megadható havi megtakarítás 3 500 Ft!',
  'Total income': 'Összes jövedelem',
  'Total income to bank': 'Bankhoz érkezhet',
  'View revenue item by item': 'Bevételek tételes megtekintése',
  'Specify revenue itemized': 'Bevétel tételes megadása',
  'Revenue type (monthly)': 'Bevétel típus (havi)',
  Amount: 'Összeg',
  'Arrived to bank': 'Bankhoz érkezett',
  'The amount must be greater than zero!':
    'Az összegnek nagyobbnak kell lennie nullánál!',
  Altogether: 'Összesen',
  altogether: 'összesen',
  'The CSOK loan can only be applied for in addition to the CSOK required for the purchase, construction and purchase of a used home. The CSOK loan has a fixed interest rate of 3% until the end of the term. The maximum amount is HUF 10 million for two children (existing or committed) and HUF 15 million for 3 children (existing or committed).':
    'A CSOK hitel kizárólag új lakás vásárlásra, építésre és használt lakás vásárlásra igényelt CSOK mellet kérhető. A CSOK hitel a futamidő végéig fix, 3%-os kamatozású. Maximális összege két gyermek (meglévő vagy vállalt) esetén 10 millió, míg 3 gyermek (meglévő vagy vállalt) esetében 15 millió forint.',
  'The Home Renovation Loan can only be applied for with at least one existing child (or 12-week-old fetus) and only if you are also eligible for the Home Renovation Allowance. The interest on the loan is the same as the CSOK loan, ie 3%, max. For a term of 10 years and max. It can be raised in the amount of HUF 6 million.':
    'Az Otthonfelújítási kölcsön kizárólag legalább egy meglévő gyermek (vagy 12 hetes magzat) esetén igényelhető és csak akkor, ha az Otthonfelújítási támogatásra is jogosult vagy. A kölcsön kamata a CSOK hitellel egyező, azaz 3%, max. 10 éves futamidőre és max. 6 millió Ft összegben vehető fel.',
  "It is obligatory to take out credit insurance for Budapest Bank's public sector discount, and it must also be checked for the offer.":
    'A Budapest Bank közszféra kedvezményéhez kötelező hitelfedezeti biztosítást kötni, az ajánlathoz azt is be kell pipálni.',
  'You can take out some mortgages at a lower interest rate or cost if you take out unemployment or incapacity insurance with your bank, for example.':
    'Egyes jelzáloghiteleket alacsonyabb kamattal vagy költséggel vehet fel, amennyiben például munkanélküliségre, keresőképtelenségre szóló biztosítást köt a banknál.',
  "doesn't matter": 'mindegy',
  'Budapest or county center': 'Budapest vagy megyei jogú',
  'Other settlement': 'Egyéb település',
  'Calculated on the basis of data': 'adatok alapján számított',
  'market only': 'csak piaci',
  'only supported': 'csak támogatott',
  'market and subsidized': 'piaci és támogatott',
  K1: 'K1',
  K2: 'K2',
  K3: 'K3',
  K4: 'K4',
  K5: 'K5',
  K6: 'K6',
  'Budapest Bank': 'Budapest Bank',
  'CIB Bank': 'CIB Bank',
  'Erste Bank': 'Erste Bank',
  'K&H Bank': 'K&H Bank',
  'OTP Bank': 'OTP Bank',
  'Raiffeisen Bank': 'Raiffeisen Bank',
  'Takarékbank Zrt.': 'Takarékbank Zrt.',
  'UniCredit Bank': 'UniCredit Bank',
  'Yes, more than 3 years': 'Igen, több mint 3 éve',
  'Yes, less than 3 years': 'Igen, kevesebb mint 3 éve',
  "Doesn't have": 'Nincs',
  'Yes, not yet settled (Active)': 'Igen, elmaradását még nem rendezte (Aktív)',
  'Yes, already settled (Passive)': 'Igen, elmaradását már rendezte (Passzív)',
  'Are the claimants married?': 'Házasok az igénylők?',
  'Do you have children?': 'Van gyermekük?',
  "Is this the bride's first marriage?": 'Ez a nő első házassága?',
  "Is this the husband's first marriage?": 'Ez a férfi első házassága?',
  'When was the bride born?': 'A pár hölgy tagja mikor született?',
  'When was the husband born?': 'A pár férfi tagja mikor született?',
  'Does at least one of the parties currently have a Social Insurance relationship?':
    'Van jelenleg legalább az egyik félnek TB jogviszonya?',
  'Does the last 180 days of Social Insurance legal relationship come from a Hungarian employee or entrepreneurial relationship?':
    'Az utolsó 180 nap TB jogviszony magyar alkalmazotti, vállalkozói jogviszonyból származik?',
  'If you have proven a foreign Social Insurance legal relationship, it is sufficient if the last 90 days come from a Hungarian employee or entrepreneurial legal relationship.':
    'Amennyiben külföldi TB jogviszonyt igazoltál le, akkor elégséges, ha az utolsó 90 nap származik magyar alkalmazotti, vállalkozói jogviszonyból.',
  'Do you have a public debt with the state tax office? (eg tax debt)':
    'Van az állami adóhazóságnál köztartozása? (pl. adótartozás)',
  'Are any parties on the negative KHR list?':
    'Szerepel bármelyik fél a negatív KHR listán?',
  'Do both parties have a Hungarian address?':
    'Mindkét félnek van magyar lakcíme?',
  'Baby support loan amount': 'Babaváró hitel összege',
  'Would you apply for a Baby Support loan to redeem your loan?':
    'Hitelkiváltásra igényelnéd a Babaváró hitelt?',
  'Do you open or have an account with the lending bank?':
    'Nyitnál vagy rendelkezel számlával a hitelt nyújtó banknál?',
  'The loan amount must be greater than HUF 100,000!':
    'A hitelösszegnek nagyobbnak kell lennie mint 100 000 Ft!',
  Income: 'Bevétel',
  "You haven't entered an income yet!": 'Még nem nem adtál meg jövedelmet!',
  'Add income': 'Jövedelem hozzáadása',
  'Income deduction': 'Jövedelem letiltás',
  Transferred: 'Utalás',
  'Received in cash': 'Készpénzben kapott',
  Modify: 'Módosít',
  'Arrives to my bank account.': 'Bankszámlára érkezik.',
  'My trial period has expired.': 'A próbaidőm már letelt.',
  'Employment / Start of activity': 'Munkaviszony / Tevékenység kezdete',
  'Type of employment': 'Munkaviszony típusa',
  Definite: 'Határozott',
  Indefinite: 'Határozattlan',
  'Enter only verifiable net revenue!':
    'Csak az igazolható nettó bevételeket add meg!',
  'Amount of revenue': 'Bevétel összege',
  'Existing loans': 'Meglévő hitelek',
  'Existing loans, credit lines, credit cards significantly affect the amount of the Baby Support loan that can be taken out and the result of the bank assessment!':
    'A meglévő hitelek, hitelkeretek, hitelkártyák jelentősen befolyásolják a felvehető Babaváró hitel összegét, és a banki elbírálás eredményét!',
  "I don't have any loans!": 'Nincs meglévő hitelem!',
  'Add an existing loan': 'Meglévő hitel hozzáadása',
  'Not to be redeemed': 'Nem kiváltandó',
  'To be redeemed': 'Kiváltandó',
  'Monthly installment': 'Havi törlesztőrészlet',
  'Loan type': 'Hitel típus',
  'Name of other type of loan': 'Egyéb hiteltípus megnevezése',
  'Existing debt': 'Fennálló tartozás',
  'Lending bank': 'Hitelt nyújtó bank',
  'Would you redeem it with the Baby Support loan?':
    'A Babaváró hitellel kiváltanád?',
  'Hungarian employee income': 'Magyar alkalmazotti jövedelem',
  'Foreign employee income': 'Külföldi alkalmazotti jövedelem',
  Overdraft: 'Folyószámlahitel',
  'Real estate leasing': 'Ingatlan lízing',
  'Credit card': 'Hitelkártya',
  'Employer loan': 'Munkáltatói kölcsön',
  'Car loan': 'Gépjárműhitel',
  'Vehicle leasing': 'Gépjármű lízing',
  'Student loan': 'Diákhitel',
  'Commodity credit': 'Áruhitel',
  'Municipal loan': 'Önkormányzati hitel',
  Other: 'Egyéb',
  'Housing mortgage': 'Lakáscélú jelzáloghitel',
  'General purpose mortgage': 'Szabad felhasználású jelzáloghitel',
  'KATA monthly net income': 'KATA havi nettó bevétel',
  'EVA monthly net income': 'EVA havi nettó bevétel',
  'Income from a Hungarian joint venture (net)':
    'Magyar társas vállalkozásból származó jövedelem (nettó)',
  'Sole proprietorship (Non-EVA, KATA)': 'Egyéni vállalkozás (Nem EVA, KATA)',
  'Family support': 'Családi pótlék',
  GYES: 'GYES',
  GYED: 'GYED',
  'TGYÁS, CSED': 'TGYÁS, CSED',
  'Child support': 'Gyermektartás',
  'Regular bonus': 'Rendszeres bónusz',
  Cafeteria: 'Cafeteria',
  'Income from letting real estate': 'Ingatlanbérbeadásból származó jövedelem',
  'Income from a foreign enterprise':
    'Külföldi vállalkozásból származó jövedelem',
  'Primary producer income': 'Őstermelő jövedelme',
  Retirement: 'Öregségi nyugdíj',
  "Widow's pension": 'Özvegyi nyugdíj',
  'Foreign pension': 'Külföldi nyugdíj',
  'Disability support': 'Fogyatékossági támogatás',
  'Disability annuity': 'Rokkantsági járadék',
  'Disability benefits': 'Rokkantsági ellátás',
  'Sickness annuity': 'Egészségkárosodási járadék',
  "Temporary widow's pension": 'Ideiglenes özvegyi nyugdíj',
  'Early retirement pension': 'Korkedvezményes nyugdíj',
  'Rehabilitation allowance / benefit (no rehabilitation allowance can be determined from 1 January 2012)':
    'Rehabilitációs járadék/ellátás (2012. január 1-jétől rehab.járadék nem állapítható meg)',
  Remuneration: 'Szolgálati járandóság',
  'Temporary mining annuity': 'Átmeneti bányászjáradék',
  'Nursing fee': 'Ápolási díj',
  'Social aid': 'Szociális segély',
  'Orphan care': 'Árvaellátás',
  'Fulltime maternity benefit (child raising allowance':
    'Főállású anyasági ellátás (gyermeknevelési támogatás',
  'Foster care fee': 'Nevelőszülői díj',
  Annuity: 'Életjáradék',
  Scholarship: 'Ösztöndíj',
  'Annuity of Olympic champions': 'Olimpiai bajnokok járadéka',
  'Daily allowance': 'Napidíj',
  'Commission fee': 'Megbízási díj',
  'Ballet art annuity': 'Balett művészeti járadék',
  'Personal allowance for the blind': 'Vakok személyi járadéka',
  'Income of church members': 'Egyházi személyek jövedelmei',
  'Honors (politicians, local representatives, etc.)':
    'Tiszteletdíj (politikusok, önkor.képviselők, stb.)',
  'Accident annuity': 'Baleseti járadék',
  'Related benefits': 'Hozzátartozói ellátások',
  'Public works': 'Közmunka',
  GYOD: 'GYOD',
  Dividend: 'Osztalék',
  'The minimum income is HUF 5,000!':
    'A minimálisan megadható jövedelem 5 000 Ft!',
  'The start of monetization cannot be in the future!':
    'A bevételszerzés kezdete nem lehet jövőbeli!',
  'Please enter a valid date!': 'Valós dátumot adjon meg!',
  'The lowest debt that can be given is HUF 1,000!':
    'A megadható legalacsonyabb tartozás 1 000 Ft!',
  'The lowest loan repayment that can be given is HUF 1,000!':
    'A megadható legalacsonyabb hiteltörlesztő 1 000 Ft!',
  Installment: 'Törlesztőrészlet',
  'Together with the monthly amount of savings combined with the loan.':
    'A hitelhez kombinált megtakarítás havi összegével együtt.',
  'Select an item!': 'Válassz ki egy elemet!',
  Incomes: 'Bevételek',
  'New loan amount + debt of loans to be redeemed':
    'Új hitelösszeg + Kiváltandó hitelek fennálló tartozása',
  'Calculated value, cannot be changed': 'Számított érték, nem módosítható',
  'New loan amount': 'Új hitelösszeg',
  'How much credit do you want to take out? (outside of loan redemption)':
    'Mekkora hitelt szeretne felvenni? (hitelkiváltáson felüli rész)',
  'Personal loan calculator': 'Személyi kölcsön kalkulátor',
  'The loan applicant...': 'A hiteligénylő...',
  'is willing to take on active contact with the bank for a more favorable loan':
    'kedvezőbb hitelért cserébe aktív bankkapcsolatot vállal',
  'You agree to fulfill tasks that are displayed on the repayment invoice of the personal loan (for example: group collection entrusting etc.)':
    'Vállalod, hogy a személyi kölcsönhöz tartozó törlesztési számlán különböző megbízások teljesüljenek (például: csoportos beszedési megbízás, stb.)',
  'has permanent hungarian address':
    'állandó magyarországi lakcímmel rendelkezik',
  'can include debtor': 'adótársat is be tud vonni',
  'is not on probation': 'már nincs próbaidő alatt',
  'Enter income': 'Bevétel rögzítése',
  'Type of income (monthly)': 'Bevétel típus (havi)',
  'Amount of income': 'Összeg',
  'Start of income': 'Kezdete',
  'Amount to go to the bank': 'Bankhoz érkezhet',
  "Amount of money that can be transfered to the bank that gives the loan (this doesn't necessarily mean the arrival of the income, it can also be monthly transfer)":
    'Az az összeg, amit a hitelt nyújtó bankhoz lehet irányítani (ez nem feltétlen jövedelemérkeztetést jelent, lehet szimplán havi rendszeres átutalás is)',
  'Income from hungarian enterprise': 'Magyar vállalkozásbó származó jövedelem',
  'Income from property rental': 'Ingatlan bérbeadás',
  'Other pension': 'Egyéb nyugdíj',
  'Transferred to bank: ': 'Bankba érkeztetett: ',
  ' ': 'óta',
  months: 'hónap',
  month: 'hónap',
  'Altogether:': 'Összesen:',
  day: 'nap',
  '3 months': '3 hónap',
  '6 months': '6 hónap',
  '1 year': '1 év',
  '3 years': '3 év',
  '4 years': '4 év',
  '5 years': '5 év',
  '6 years': '6 év',
  '8 years': '8 év',
  '10 years': '10 év',
  '15 years': '15 év',
  '20 years': '20 év',
  Sberbank: 'Sberbank',
  'Enter old loan': 'Meglévő hitel rögzítése',
  'Date of borrowing': 'Hitelfelvétel időpontja',
  'Can also be an estimate (important: it should be the actual debt, not the starting debt)':
    'Lehet becsült összeg is (fontos, hogy ne a kezdetben felvett összeg, hanem az aktuális hiteltartozás legyen).',
  'Has to be the exact amount, otherwise a precise calculation cannot be given to the client':
    'Pontos összeget kell megadni, e nélkül nem adható jó kalkuláció az ügyfél részére',
  'Always paid on time': 'Mindig időben fizette',
  'Weekly installments': 'Heti törlesztésű hitel',
  'Mortgage loan': 'Jelzáloghitel',
  'Personal loan': 'Személyi kölcsön',
  'Baby loan': 'Babaváró hitel',
  'Loan for car': 'Autóhitel',
  'Credit line': 'Hitelkeret',
  'Housing savings loan before expiration of saving period':
    'Lakástakarék hitele megtakarítási időszak lejárta előtt',
  'Housing savings loan after saving period':
    'Lakástakarék hitele megtakarítási időszak után',
  'Always paid on time!': 'Mindig rendben fizette!',
  "Didn't always pay on time!": 'Nem mindig fizette rendben!',
  Weekly: 'Heti',
  Monthly: 'Havi',
  installment: 'törlesztőrészlet',
  'Old loans': 'Meglévő hitelek',
  'Total of current debts': 'Összes fennálló tartozás',
  'Total of current installments': 'Összes törlesztőrészlet',
  'Total of current to be redeemed debts':
    'Kiváltandó hitelek fennálló tartozása',
  'Total of current to be redeemed installments':
    'Kiváltandó hitelek törlesztőrészlete',
  'Rest of current debts': 'Megmaradó hitelek fennálló tartozása',
  'Rest of current installments': 'Megmaradó hitelek törlesztőrészlete',
  'Calculated value': 'Számított érték',
  'The least amount of loan that you can take out is HUF 30 000! Please increase the new loan amount, or enter a new redeemable loan.':
    'A minimálisan felvehető hitelösszeg 30 000 Ft! Növelje az új hitelösszeget, vagy adjon meg kiváltani kívánt hitelt a meglévő hitel rögzítése pontba.',
  'The maximum duration is 10 years!':
    'A maximálisan megadható futamidő 10 év!',
  'You must be of age to take out a loan!':
    'Kiskorú személy nem vehet fel hitelt!',
  'Enter at least one income!': 'Adjon meg legalább egy jövedelmet!',
  'The maximum age is 100!': 'A megadható legmagasabb életkor 100 év!',
  'Please enter the type of income!': 'Kérem adja meg a bevétel típusát!',
  'The income must be at least HUF 5 000!':
    'A megadható legalacsonyabb bevétel 5 000 Ft!',
  'The starting date of income must be after your birthdate!':
    'A bevételszerzés kezdete nem lehet előbb mint a születési dátum!',
  'Invalid date!': 'Érvénytelen dátum!',
  'Please enter the amount to be transferred to the bank!':
    'Az érkeztetett összeg megadása kötelező!',
  'Please enter the type of the loan!': 'Kérem adja meg a hitel típusát!',
  'The take-out date of the loan must be after your birthdate!':
    'A hitelfelvétel időpontja nem lehet korábbi mint a születési dátum!',
  'The installment must be at least HUF 1 000':
    'A megadható legalacsonyabb hiteltörlesztő 1 000 Ft!',
  'The debt must be at least HUF 1 000':
    'A megadható legalacsonyabb tartozás 1 000 Ft!',
  'Choose an income type!': 'Válassz ki egy jövedelem típust!',
  'Choose a loan type!': 'Válassz ki egy hitel típust!',
  'Choose an employment type!': 'Válassz egy munkaviszony típust!',
  'Choose a bank!': 'Válassz egy bankot!',
  'Choose a revenue type!': 'Válassz egy bevétel típust!',
  'Enter the exact name of the loan!': 'Adja meg a hitel pontos megnevezését!',
  Calculate: 'Kalkulálás',
  'Select an option': 'Válasszon egy opciót!',
  'It is mandatory to specify the type of income!':
    'A bevétel típusának megadása kötelező!',
  'This field is required!': 'A mező kitöltése kötelező!',
  'The minimum income is HUF 5,000':
    'A minimálisan megadható jövedelem 5 000 Ft',
  'Please select from the list!': 'Kérjük válassz a listából!',
  'Date is required!': 'A dátum megadása kötelező!',
  'Enter a loan type!': 'Adjon meg egy hiteltípust!',
  'Enter the name of the lending bank!': 'Adja meg a hitelező bank nevét!',
  'You have no banks selected!': 'Nem választottál ki egy bankot sem!',
  'Please select an interest period!': 'Nincs kiválasztva kamatperiódus!',
  'At least one income source must be entered!':
    'Legalább egy jövedelem megadása kötelező!',
  'Confirm that you do not have any loans, or add at least one loan!':
    'Jelölje be, hogy nem rendelkezik hitellel, vagy adjon hozzá legalább egy hitelt!',
  'Customer and consulant details': 'Ügyfél és közvetítő adatai',
  Mortgage: 'Jelzáloghitel',
  'Personal loan': 'Személyi kölcsön',
  'Baby support loan': 'Babaváró hitel',
  'Customer data': 'Ügyfél adatok',
  Address: 'Lakcím',
  'Notification address': 'Értesítési cím',
  'Birth place': 'Születési hely',
  'Birth date': 'Születési idő',
  'Tax identification number': 'Adóazonosító jel',
  'Telephone number': 'Telefonszám',
  'E-mail address': 'Email cím',
  'Name of tax partner (mandatory in case of baby waiting)':
    'Adótárs neve (Babaváró esetén kötelező)',
  'Consulant data': 'Közvetítő adatok',
  'Name of consulant company': 'Közvetítő cég neve',
  'Consulant company address': 'Közvetítő cég cím',
  'Tax code of the consulant company': 'Közvetítő cég adószáma',
  'Consulant name': 'Közvetítő neve',
  years: 'év',
  'Choose new loan': 'Új hitel választása',
  'Monthly repay': 'Havi törlesztő',
  APR: 'THM',
  'Entire loan': 'Teljes visszafizetés',
  'Fix installment': 'Fix törlesztő',
  'The complete repay includes the installments and other expenses':
    'A teljes visszafizetés tartalmazza a törlesztőrészleteket és a költségeket',
  'Details of the loan': 'A hitel részletes adatai',
  'Product name': 'Terméknév',
  'Raiffeisen Mortgage': 'Raiffeisen Jelzáloghitel',
  'Name of the bank': 'Bank neve',
  Market: 'Piaci',
  Subtype: 'Altípus',
  'Basic information': 'Alapadatok',
  'Loan summary': 'Hitelösszeg',
  HUF: 'Ft',
  'year(s)': 'év',
  'Properties of mortgage': 'Jelzáloghitel tulajdonságai',
  'Loan goal': 'Hitelcél',
  'Buying a new house': 'Új lakás vásárlás',
  'Starting installment': 'Induló törlesztés',
  'Monthly price of credit protection insurance (outside of installment)':
    'Hitelfedezeti biztosítás havi díja (törlesztőn felül)',
  'Government supported time': 'Államilag támogatott időszak',
  'Interest (during support / instant bridging time)':
    'Kamat (támogatás alatt / azonnali áthidaló időszak)',
  'Interest (market / after support / mortgage time)':
    'Kamat (piaci / támogatás után / lakáskölcsön időszak)',
  'Interest period (during support / instant bridging time)':
    'Kamatperiódus (támogatás alatt / azonnali áthidaló időszak)',
  'Interest period (market / after support / mortgage time)':
    'Kamatperiódus (piaci / támogatás után / lakáskölcsön időszak)',
  'Complete payback (only installments)':
    'Teljes visszafizetés (csak törlesztőrészlet)',
  'Complete payback (installments + expenses)':
    'Teljes visszafizetés (törlesztőrészletek + költségek)',
  Expenses: 'Költségek',
  'Starting expense': 'Induló költség',
  'Ownership page (not official)': 'Tulajdoni lap (nem hiteles)',
  'Copy of map': 'Térképmásolat',
  'Ownership page (e-official)': 'Tulajdoni lap (e-hiteles)',
  'Worth estimation': 'Értékbecslés',
  'Notary public fee (estimated amount)': 'Közjegyzői díj (becsült összeg)',
  'Mortgage registration fee': 'Jelzálog bejegyzési díj',
  'Disbursement contribution': 'Folyósítási jutalék',
  'Prepay fee': 'Előtörlesztési díj',
  'Prepay fee (self-sufficiently)': 'Előtörlesztési díj (önerőből)',
  'Prepay fee (from other loan)': 'Előtörlesztési díj (másik hitelből)',
  'Final repayment fee': 'Végtörlesztési díj',
  'Final repayment fee (self-sufficiently)': 'Végtörlesztési díj (önerőből)',
  'Final repayment fee (from other loan)':
    'Végtörlesztési díj (másik hitelből)',
  'Account management fee': 'Számlavezetési díj',
  'Contract modification fee': 'Szerződésmódosítási díj',
  'Search filters': 'A keresés feltételei',
  'Income, other transfers to the bank':
    'Jövedelem, egyéb jóváírás bankhoz irányítása',
  'Active card usage': 'Aktív kártyahasználat',
  'Loan coverage assurance': 'Hitelfedezeti biztosítás',
  'Szocpol / CSOK': 'Szocpol / CSOK',
  'Coverage verify fee in regards to the loan payment':
    'Kölcsön kifizetéséhez kapcsolódó Fedezetellenőrzési díj',
  'In case of offering a detached house, twin house or flat when submitting a loan application, the following copy of map to be submited will be processeed at the retrieval in the bank account.':
    'A hitelkérelem benyújtásakor családi ház, ikerház, vagy sorház fedezetként történő felajánlása esetén, a kötelezően benyújtandó térképmásolat bankfiókban történő lekérésekor kerül felszámításra.',
  'Coveragee verify fee in regards to the loan submiting':
    'Kölcsönigényléshez kapcsolódó Fedezetellenőrzési díj',
  'from which': 'amelyből',
  'will be paid back': 'kerül visszafizetésre',
  'in place of': 'helyett',
  'but at most': 'de max.',
  '1-3-5% interest increase': '1-3-5% kamatemelkedés',
  'Interest path change': 'Kamatpálya változás',
  'Use of house savings': 'Lakástakarék igénybevétele',
  'House savings and mortgage at different times':
    'Lakástakarék és jelzáloghitel eltérő időpontban',
  'Interest period comparator': 'Kamatperiódus összehasonlító',
  'This is how the installments change in case of interest increase':
    'Kamatemelkedés esetén így változik a hitel törlesztőrészlete',
  'The effects of the interest increase on the whole repayment':
    'A kamatemelkedés hatása a teljes visszafizetésre',
  'month(s)': 'hónap',
  'In case of a 1-5% interest increase, the installments increase to':
    '1-5% pontos kamatemelkedés hatására a hitel törlesztőrészlete',
  'which is equal to': '-ra növekszik, ami',
  'of increase': '-os emelkedésnek felel meg',
  'The installments increase in a greater volume than the interest increase':
    'A törlesztőrészlet a kamatemelkedés üteménél nagyobb mértékben növekszik',
  'By the effect of the interest increase, the entire loan increases to':
    'A kamatemelkedés hatására a teljes visszafizetés',
  'Calculation methodology': 'Számítási módszertan',
  'In the above text': 'A fentiekben',
  "at the quantification of the interest increase's negative effects":
    'a kamatemelkedés kedvezőtlen hatásának számszerűsítésénél',
  'we assume': 'abból indultunk ki',
  'that the 1-3-5% interest increase occurs directly after the take out of the loan':
    'hogy az 1-3-5%pontos kamatemelkedés közvetlen a hitelfelvétel után következik be',
  'In case the interest increase occurs later':
    'Amennyiben a kamatemelkedés később következik be',
  'the negative effect on the installments will be more moderate':
    'a havi törlesztőrészletre gyakorolt kedvezőtlen hatás mérsékeltebb lesz',
  'the later the interest increase occurs, the less the effect will be':
    'minél későbbi a kamatemelkedés, annál kisebb mértékű a hatás',
  'Interest path simulator': 'Kamatpálya szimulátor',
  'We are examining how the loan changes regarding to the given interest changes':
    'Azt vizsgáljuk, hogy a megadott kamatváltozások esetén, hogyan viselkedik a hitel',
  'Interest changes and their values': 'Kamat változások időpontja és mértéke',
  'House saving repayment': 'Lakástakarék betörlesztés',
  'The government pays a 30% aid to the repayment of the house savings':
    'A lakástakarék szerződés befizetéseire az állam 30%-os támogatást fizet',
  'So the attainable guaranteed return with the mortgage exceeds the interest of the loan, which positively effects the loan borrower':
    'Így a lakástakarékkal elérhető garantált hozam meghaladhatja a hitel kamatát, mely pénzügyileg kedvező hatást eredményez a hitelfelvevő számára',
  'With comparison of the selected loans with different interest periods, we examine how the loans will behave in case of interest change(s) during the term':
    'Tetszőlegesen kiválasztott eltérő kamatperiódusú hitelek összehasonlításával azt vizsgáljuk meg, hogy melyik hitel hogyan viselkedik a futamidő alatti kamatváltozás(ok) esetén',
  'Products with different interest periods': 'Eltérő kamatperiódusú termékek',
  january: 'január',
  february: 'február',
  march: 'március',
  april: 'április',
  may: 'május',
  june: 'június',
  july: 'július',
  august: 'augusztus',
  september: 'szeptember',
  october: 'október',
  november: 'november',
  december: 'december',
  'Add interest change': 'Kamatváltozás hozzáadása',
  Month: 'Hónap',
  'Change volume': 'Változás mértéke',
  Interest: 'Kamat',
  Removed: 'Eltávolítva',
  'The interest change is succesfully deleted':
    'A kamatváltozás sikeresen törölve',
  Added: 'Hozzáadva',
  'The interest change is succesfully recorded':
    'A kamatváltozás sikeresen rögzítve',
  'The interest change is succesfully modified':
    'A kamatváltozás sikeresen módosítva',
  'When the interest changes, this is how the installments change':
    'Kamatváltozás hatására így módosul a hitel törlesztőrészlete',
  'First installment': 'Induló törlesztő',
  'Highest installment': 'Legmagasabb törlesztő',
  'In case the above given interest changes occur, the installmments could move between':
    'A fent megadott kamatváltozások bekövetkezése esetén a hitel törlesztőrészlete',
  'and the entire loan could grow to':
    'között mozoghat, és a teljes visszafizetés',
  'which is a': '-ra nőhet, ami',
  change: '-os változás',
  'Monthly saving': 'Havi megtakarítás',
  'Start of house savings': 'Lakástakarék kezdete',
  'Start of mortgage': 'Jelzáloghitel kezdete',
  'Monetary benefit attainable by the house saving':
    'Lakástakarékkal elérhető pénzügyi előny',
  'Remaining house saving': 'Fennmaradó lakástakarék',
  to: '-tal',
  'With the signing of the house saving contract, as a positive effect of the government aid (considering the monthly amounts that are to be paid into the house savings) the entire loan can be reduced by':
    'A lakástakarék szerződés megkötésével, az állami támogatás kedvező hatásaként (figyelembe véve a lakástakarékba fizetendő havi összegeket is) a teljes visszafizetés',
  'the THM of the loan can be reduced by': '-ra csökkenhet, a hitel THM-je',
  'to ': '-kal',
  "(the APR can also be negative in case there's a big leftover from the last house saving)":
    '-ra csökkenthető (a THM negatív is lehet, amennyiben az utolsó lakástakarékból jelentősebb összeg marad meg)',
  'The remaining house saving': 'A fennmaradó lakástakarék',
  'is free to be spent with house purposes after the loan has completely been paid back':
    'a hitel teljes visszafizetését követően lakáscélra szabadon elkölthető összeg',
  'Entire debt': 'Tőketartozás',
  'Monthly expenses': 'Havi költség',
  'Only loan (without house saving)': 'Csak hitel (lakástakarék nélkül)',
  'Loan with house saving': 'Hitel és lakástakarék együtt',
  Period: 'Időszak',
  'Click here to choose a product': 'Kattints ide egy termék kiválasztásához',
  'Installment and entire loan': 'Törlesztőrészlet és teljes visszafizetés',
  'Interest period': 'Kamatperiódus',
  Starting: 'Induló',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  year: 'év',
  'Debt from the entire loan at the end of the period':
    'Tőketartozás a megadott időszak végén',
  'Monthly installment + house savings':
    'Havi törlesztő + lakástakarék megtakarítás',
  '1% interest increase': '1% kamatemelkedés',
  '3% interest increase': '3% kamatemelkedés',
  '5% interest increase': '5% kamatemelkedés',
  'Interest increase': 'Kamatemelkedés',
  Original: 'Eredeti',
  Amended: 'Módosult',
  "The vertical lines show the turning point of the loan's interest period":
    'A függőleges vonalak az adott hitel kamatperiódusának a fordulópontját mutatják',
  "that's when the loan's interest and installment may change":
    'potenciálisan ekkor változhat a hitel kamat és így a törlesztőrészlete',
  'Evolution of the credit debt': 'A hiteltartozás alakulása',
  'Only loan': 'Csak hitel',
  'without house savings': 'lakástakarék nélkül',
  'Loan and house savings together': 'Hitel és lakástakarék együtt',
  'Evolution of monthly expenses (mortgage repay and house savings)':
    'A havi kiadás (jelzáloghitel törlesztés és a lakástakarék befizetés) alakulása',
  'Budapest Bank Market interest rate mortgage':
    'Budapest Bank Piaci kamatozású jelzáloghitel',
  'CIB 5 Qualified Consumer friendly mortgage':
    'CIB5 Minősített Fogyasztóbarát Jelzáloghitel',
  Error: 'Hiba',
  'The monthly saving cannot be less than':
    'A havi megtakarítás értéke nem lehet kevesebb mint',
  Warning: 'Figyelmeztetés',
  'The starting date of mortgage has been changed':
    'A lakástakarék kezdő időpontja módosítva lett',
  'HUF/month': 'Ft/hó',
  'Interest periods': 'Kamatperiódusok',
  'Fixed installment (interest period)': 'Fix törlesztőrészlet (kamatperiódus)',
  'Detailed search': 'Részletes keresés',
  'Filter loans': 'Hitelek szűrése',
  'Birthday of debtor': 'Adós születési ideje',
  Budapest: 'Budapest',
  'Type of settlement': 'Település típusa',
  'MKB Bank': 'MKB Bank',
  Bank: 'Bank',
  'Available discounts': 'Elérhető kedvezmények',
  'Low energy property': 'Alacsony energiájú ingatlan',
  'K&H premium banking client': 'K&H prémium banki ügyfél',
  'Family home creation subvention available':
    'Családi otthonteremtési kedvezmény elérhető',
  'Public sector employee': 'Állami alkalmazott',
  'Active workplace discount (Budapest Bank discount)':
    'Aktív munkahelyi kedvezmény (Budapest bank kedvezmény)',
  'Erste Bank premium word or private banking client':
    'Erste bank prémium vagy privát banki ügyfél',
  'Commits to active account usage': 'Aktív fiókhasználatot vállal',
  'Commits to credit insurance conclusion': 'Hitelbiztosítást vállal',
  'Commits to account management': 'Fiókkezelést vállal',
  'Commits to home insurance conclusion': 'Lakásbiztosítást vállal',
  'Commits to active card usage': 'Aktív kártyahasználatot vállal',
  'Commits to wages arriving at the bank':
    'Vállalja, hogy a bére a bankhoz érkezik',
  'Commits to conclusion life insurance at':
    'Életbiztosítást vállal a következőnél',
  'Add revenue': 'Bevétel hozzáadása',
  'You have not added revenues yet': 'Még nem adtál meg bevételt',
  'You have not added loans yet': 'Még nem adtál meg hitelt',
  'How does the monthly installment change in the event of an exact 1-3-5% interest rate increase?':
    'Hogyan változik a havi törlesztőrészlet 1-3-5% pontos kamatemelkedés esetén?',
  'How does the monthly installment change if the loan interest rate changes in the future?':
    'Hogyan változik a havi törlesztőrészlet, ha a jövőben változik a hitel kamata?',
  'Decreasing total repayment, lower APR.':
    'Csökkenő teljes visszafizetés, alacsonyabb THM.',
  'Comparison of products with different interest periods.':
    'Eltérő kamatperiódusú termékek összehasonlítása.',
  'Is on probation time': 'Próbaidő alatt van',
  'Name of employer': 'Munkáltató neve',
  'Tax number of employer': 'Munkáltató adószáma',
  'Choose a credit type!': 'Válassz ki egy hitel típust!',
  'Every two weeks': 'Kéthetente',
  'Every two months': 'Kéthavonta',
  'Borrowing date': 'Hitel felvételének dátuma',
  'Current debt': 'Fennálló tartozás',
  'Choose a credit purpose!': 'Válassz ki egy hitelcélt!',
  'Payment frequency': 'Fizetés gyakorisága',
  'Baby support loan discounts': 'Babaváró hitel kedvezmények',
  'Do you have any debts at the state tax authority? (for example tax debt)':
    'Van az állami adóhazóságnál köztartozása? (pl. adótartozás)',
  "The marriage's female member is younger than 41 years old?":
    'A házasság női tagja 41 évnél fiatalabb?',
  'Both parties have hungarian address': 'Mindkét félnek van magyar lakcíme?',
  'The last 180 days of social security stems from employment or entrepreneur relationship':
    'Az utolsó 180 nap TB jogviszony magyar alkalmazotti, vállalkozói jogviszonyból származik?',
  'Would you commit to opening an account or already have one at the lending bank?':
    'Nyitnál vagy rendelkezel számlával a hitelt nyújtó banknál?',
  'Starting page': 'Kezdőoldal',
  'CSOK loan': 'CSOK hitel',
  'Green loan': 'Zöld hitel',
  'Loan refinancing': 'Hitelkiváltás',
  Identification: 'Azonosító',
  'Enter client': 'Ügyfél megadása',
  'Client identification': 'Ügyfél azonosító',
  'Add client': 'Ügyfél hozzáadása',
  "Consultant's data": 'Közvetítő adatai',
  'Consultant company name': 'Közvetítő cég neve',
  'Consultant company address': 'Közvetítő cég címe',
  'Consultant company tax number': 'Közvetítő cég adószáma',
  'Consultant name': 'Közvetítő neve',
  "Client's data": 'Ügyfél adatai',
  "Debtor's name (Mandatory at baby support loan)":
    'Adóstárs neve (Babaváró hit. köt.)',
  'You cannot pick a future date': 'Nem adhatsz meg jövőbeli dátumot',
  "You have to enter the new contract's interest period in case of loan refinancing":
    'Hitelkiváltás esetén az új szerződésre vonatkozó kamatperiódust kell megadni',
  'The duration cannot be greater than 20 years!':
    'A futamidő nem lehet több mint 20 év!',
  'The duration cannot be greater than 25 years!':
    'A futamidő nem lehet több mint 25 év!',
  'This field should not be blank': 'Ez a mező nem maradhat üresen',
  'The family income must be greater than HUF 1!':
    'A család összhavi keresetének többnek kell lennie mint 1 Ft!',
  'The maximum duration is 10 years in case of a personal loan!':
    'A maximálisan megadható futamidő 10 év személyi kölcsön esetén!',
  'Please enteer the type of your settlement':
    'Kérem adja meg a településének típusát',
  Start: 'Kezdete',
  End: 'Vége',
  Location: 'Helyszín',
  'Create new event': 'Új esemény létrehozása',
  Invitations: 'Meghívások',
  'Bank account': 'Bankszámla',
  "Consultant's registration number": 'Közvetítő törzsszáma',
  'Record keeping / company registration number':
    'Nyilvántartási / cégjegyzék szám',
  'Add debtor': 'Adós hozzáadása',
  'Debtor identification': 'Adós azonosító',
  Debtor: 'Adós',
  'Co-Debtor': 'Adóstárs',
  'Co-debtors': 'Adóstársak',
  'Created date': 'Létrehozás dátuma',
  'Contract porcess status': 'Kötési folyamat státusza',
  'Expiration date': 'Lejárat dátuma',
  'There is no contract process': 'Nincs kötési folyamat',
  'Debtor(s)': 'Adós(ok)',
  'Select debtor': 'Adós választása',
  'Select co-debtor': 'Adóstárs(ak) választása',
  'Required to choose!': 'Kötelező választani!',
  'You can choose at least {{min}} person!': 'Minimum {{min}} fő választható!',
  'You can choose less than {{max}} person!': 'Maximum {{max}} fő választható!',
  'You can choose at least {{min}} and less than {{max}} person!':
    'Minimum {{min}} fő, maximum {{max}} fő választható!',
  'You can choose at least 0 and less than 3 person!':
    'Minimum 0 fő, maximum 3 fő választható!',
  'Delete debtor': 'Adós törlése',
  "You cannot select the customer, enter your date of birth in the Client's Register":
    'Ügyfél nem választható, add meg a születési dátumát az Ügyfélnyilvántartóban!',
  "You cannot select the customer, enter your place of birth in the Client's Register!":
    'Ügyfél nem választható, add meg a születési helyét az Ügyfélnyilvántartóban!',
  "You cannot select the customer, enter your address in the Client's Register!":
    'Ügyfél nem választható, add meg a címét az Ügyfélnyilvántartóban!',
  "You cannot select the customer, enter your phone number in the Client's Register!":
    'Ügyfél nem választható, add meg a telefonszámát az Ügyfélnyilvántartóban!',
  "You cannot select the customer, enter your email in the Client's Register!":
    'Ügyfél nem választható, add meg az email címét az Ügyfélnyilvántartóban!',
  "You cannot select the customer, enter the tax number in the Client's Register!":
    'Ügyfél nem választható, add meg az adóazonosító jelét az Ügyfélnyilvántartóban!',
  "You cannot select the customer, cross-authenticate the customer in the Client's Register!":
    'Ügyfél nem választható, kereszthitelesítsd az ügyfelet az Ügyfélnyilvántartóban!',
  'You cannot select the customer, the customer selected as debtor!':
    'Ügyfél nem választható, már ki van választva mint adós!',
  'Please, choose at least three products from different banks':
    'Kérlek, válassz legalább három különböző banknál lévő terméket',
  'Would you like to calculate green loan?':
    'Zöld hitelt szeretnél kalkulálni?',
  'I would only like to see banks where CSOK can be requested':
    'Azokat a bankokat szeretném látni, ahol igényelhető CSOK',
  'Are any of the debtors a public worker?':
    'Adós vagy adóstárs közalkalmazott?',
  'Terms and conditions at the lending bank':
    'Vállalt feltételek a hitelt nyújtó banknál',
  'Takes out credit protection life insurance:':
    'Hitelfedezeti életbiztosítást köt:',
  'Income recognition': 'Jövedelemérkeztetés',
  Discounts: 'Kedvezmények',
  'Premium or private bank client': 'Prémium vagy privátbanki ügyfél',
  "Worker's package discount assigned by employer at":
    'Dolgozói csomag kedvezmény munkáltató által meghatározva',
  'Checking the necessary conditions with the lending bank':
    'Szükséges feltételek ellenőrzése a hitelt nyújtó banknál',
  "People under 18 can't take out a loan!":
    '18 év alatti személy nem vehet fel hitelt!',
  "The whole of the family's income": 'A család összhavi keresete',
  'Takes out home insurance at': 'Lakásbiztosítást köt',
  'Do you need to issue a Credit Card and open a Credit Line':
    'Igényt tart Hitelkártya kibocsátására és Hitelkeret megnyitására',
  'Did you claim a mortgage loan worth of at least HUF 2M from OTP bank in the last 60 days':
    'Igényt az elmúlt 60 napban az OTP Banktól legalább 2M Ft összegű jelzáloghitelt',
  'American dollar': 'Amerikai dollár',
  'Swiss franc': 'Svájci frank',
  'Hungarian forint': 'Magyar forint',
  Currency: 'Devizanem',
  'Choose the credit line': 'Válaszd ki a hitelkeretet',
  'Choose the currency': 'Válaszd ki a devizanemet',
  '200.000 - 500.000 Ft': '200.000 - 500.000 HUF',
  '500.000 +': '500.000 +',
  'Calculated value, cannot be changed! Equals to the debt old loans that are to be redeemed.':
    'Számított érték, nem módosítható! Egyenlő a kiváltandó meglévő hitelek összegével.',
  'Client name': 'Ügyfél neve',
  'Generate PDF': 'PDF készítése',
  'Name of product': 'Termék név',
  'Important rules!': 'Fontos szabályok!',
  'Calculated data (children are born)': 'Kalkulált adatok (gyermek születik)',
  'Calculated data (NO children are born)':
    'Kalkulált adatok (NEM születik gyermek)',
  Validity: 'Érvényesség',
  'Of which capital repayment': 'Ebből tőketörlesztés',
  'Invalid tax number format': 'Helytelen adószám formátum',
  'Started by me': 'Általam indított kalkulációk',
  "Related to my team's clients": 'Csapatom ügyfeleihez tartozó',
  'Started by team': 'Csapatom kalkulációi',
  'Sent date': 'Kiküldés dátuma',
  'All calculations': 'Összes kalkuláció',
  "My specialist's calculations": 'Hitelspecialistáim kalkulációi',
  'Failed to get URL': 'Nem sikerült lekérni az URL-t',
  'The calculation summary you want to open has already been accepted by your client, and the calculation can no longer be edited!':
    'A megnyitni kívánt kalkulációból készült tájékoztatót az ügyfeled korábban már elfogadta, a kalkuláció már nem szerkeszthető!',
  Client: 'Ügyfél',
  Village: 'Falu',
  'Large village': 'Nagyközség',
  Town: 'Város',
  'County capital': 'Megyeszékház',
  'Favored city': 'Előnyben részesített város',
  'Permanent hungarian address': 'Állandó magyar lakcím',
  'I would like to redeem it': 'Kiváltanám',
  'You have entered invalid data': 'Hibás adatokat adtál meg',
  'The debtor must be at least 18 years old':
    'Az adóstársnak minimum 18 évesnek kell lennie',
  'There cannot be a minus number of children': 'Nem lehet minusz számú gyerek',
  'The duration cannot be lesser than 3 months!':
    'A futamidő nem lehet kevesebb mint 3 hónap',
  'The installment cannot be greater than the current debt':
    'A törlesztőrészlet nem lehet nagyobb mint maga a hitel',
  Sector: 'Szektor',
  'Thank you, we received your order': 'Köszönjük, megkaptuk rendelésed',
  'Send e-mail': 'E-mail küldése',
  'Forgotten password': 'Elfelejtett jelszó',
  'Reset password': 'Jelszó visszaállítása',
  'These two values must be the same': 'Ennek a két értéknek meg kell egyeznie',
  Broker: 'Üzletkötő',
  Calculator: 'Kalkulátor',
  'Mandatory For Groups': 'Kötelező szerkesztési címkék',
  'Edit published version': 'Publikált verzió szerkesztése',
  'Zip code': 'Irányítószám',
  'New box': 'Új doboz',
  'New separator': 'Új elválasztó ',
  separator: 'elválasztó',
  'New header': 'Új fejléc',
  'New subheader': 'Új alfejléc',
  'Mobile account': 'Mobil fiók',
  Personal: 'Személyes',
  Calendar: 'Naptár',
  'Are you sure of editing this published version?':
    'Ezzel az éles cikket módosítod, biztos vagy benne?',
  'Edit published article': 'Publikált cikk szerkesztése',
  'Anything missing from here?': 'Hiányzik valami innen?',
  'Secondary password': 'Másodlagos jelszó',
  Price: 'Ár',
  'Product type': 'Termék típusa',
  'Product status': 'Termék státusza',
  'Similar product(s)': 'Hasonló termék(ek)',
  'Further pictures': 'További képek',
  Synchronize: 'Szinkronizálás',
  'Income Category': 'Jövedelem Kategória',
  Admissibility: 'Elfogadhatóság',
  'Income Type': 'Bevétel Típus',
  'Loan Type': 'Hitel Típus',
  'Variants Count': 'Variánsok',
  'Assigned Groups': 'Engedélyezett csoportok',
  'Administrator Groups': 'Adminisztrátor csoportok',
  'Manager Groups': 'Kezelő csoportok',
  Retry: 'Újra',
  'Bank Account calculations': 'Bankszámla kalkulációk',
  'Mortgage calculation creator': 'Jelzáloghitel kalkuláció készítő',
  'Mortgage calculations': 'Jelzáloghitel kalkulációk',
  'Mortgage calculation': 'Jelzáloghitel kalkuláció',
  'Calculation data are not available...':
    'Kalkulációs adatok nem elérhetőek...',
  'Personal loan calculation creator': 'Személyi kölcsön kalkuláció készítő',
  'Personal loan calculations': 'Személyi kölcsön kalkulációk',
  'Personal loan calculation': 'Személyi kölcsön kalkuláció',
  'Baby loan calculation creator': 'Babaváró hitel kalkuláció készítő',
  'Baby loan calculations': 'Babaváró hitel kalkulációk',
  'Baby loan calculation': 'Babaváró hitel kalkuláció',
  'Maturity years': 'Futamidő',
  'Eco friendly': 'Fogyasztó barát',
  'Real estate value': 'Ingatlan értéke',
  'Current credit line': 'Meglévő hitel összeg',
  'Current installment': 'Meglévő törlesztőrészlet',
  'Monthly deposit': 'Havi vállalt megtakarítás',
  'Number of children commitment': 'Gyermekvállalások száma',
  'Number of children current': 'Meglévő gyermekek száma',
  Pledges: 'Vállalások',
  'Monthly installments': 'Havi törlesztő',
  'Full amount': 'Teljes visszafizetés',
  'Starting installment': 'Kezdeti törlesztő',
  'Startup expenses': 'Induló költség',
  'Unique Interest Rate Calculator': 'Egyedi kamat kalkulátor',
  'Interest period': 'Kamatperiódus',
  'Initial interest': 'Kezdeti kamat',
  'Full amount (installment only)': 'Teljes visszafizetés (csak törlesztő)',
  'MT number:': 'MT szám:',
  'Three months': '3 hónap',
  'Six months': '6 hónap',
  'One year': '1 év',
  'Three years': '3 év',
  'Five years': '5 év',
  'Twelve months': '12 hónap',
  'Ten years': '10 év',
  'Fifteen years': '15 év',
  'Twenty years': '20 év',
  'Twenty-five years': '25 év',
  'Thirty years': '30 év',
  Full: 'Teljes',
  Reopen: 'Újranyitás',
  'Results per page': 'Találat oldalanként',
  Specialist: 'Specialista',
  subgroup: 'Alcsoport',
  'Created by': 'Létrehozó',
  client: 'Ügyfél',
  created: 'Létrehozva',
  specialist: 'Hitelspecialista',
  'Co-debtor': 'Adóstárs',
  Add: 'Hozzáadás',
  'Permanent address': 'Állandó lakcím',
  'An error was encountered while loading data.':
    'Az adatok betöltése során hiba lépett fel.',
  'Make calculation': 'Kalkuláció készítése',
  Validation: 'Ellenőrzés',
  'Add existing loan': 'Meglévő hitel hozzáadása',
  'Which debtor do you chose?': 'Melyik hitelszereplőt választod?',
  'Mortgage calculation': 'Jelzáloghitel kalkuláció',
  Calculation: 'Kalkuláció',
  Calculations: 'Kalkulációk',
  calculation: 'kalkuláció',
  'Monthly payment amount': 'Havi törlesztőrészlet',
  'Total payment amount': 'Teljes visszafizetés',
  Discount: 'Kedvezmény',
  Message: 'Üzenet',
  'Waiting for a special interest rate': 'Egyedi kamatra vár',
  'End of special interest rate': 'Egyedi kamat lejárata',
  chosen: 'kiválasztva',
  'Edit calculation': 'Kalkuláció szerkesztés',
  'View calculation': 'Kalkuláció megtekintés',
  'Starting the contract process': 'A szerződéskötési folyamat indítása',
  Launching: 'Indítás',
  Sent: 'Kiküldve',
  Sign: 'Megkötöm',
  'Request personal calculation': 'Egyedi kamat igénylés',
  Send: 'Kiküldés',
  'The calculation list is empty, create a new calculation!':
    'A kalkulációk listája jelenleg még üres, adj hozzá új kalkulációt!',
  'Starting expense after cashback': 'Induló költség visszatérítés után',
  'Starting interest': 'Induló kamat',
  'Wait time': 'Türelmi idő',
  'Wait time payment': 'Türelmi idő alatti törlesztő',
  'Select office': 'Bankfiók kiválasztása',
  'Select clerk': 'Ügyintéző kiválasztása',
  'Special interest rate': 'Egyedi kamat',
  'Calculated data': 'Kalkulált adatok',
  Office: 'Iroda',
  Clerk: 'Ügyintéző',
  'Send calculation': 'Kalkuláció kiküldése',
  'Monthly price of credit protection insurance':
    'Hitelfedezeti biztosítás havi díja',
  'Complete payback ( +expenses)': 'Teljes visszafizetés ( +költségek)',
  'Transferred to bank': 'Bankba érkeztetett',
  'Considered by the bank': 'Bank által figyelembe vett',
  'Additional details': 'További részletek',
  'Acceptance Count': 'Elfogadások száma',
  'Content Id': 'Tartalom azonosítója',
  'Content Title': 'Tartalom címe',
  'Content Type': 'Tartalom típusa',
  Conditions: 'Kondíciók',
  'Are you sure of duplicating this version?': 'Biztosan duplikálod a verziót?',
  Clerks: 'Kapcsolattartók',
  PartnerOffices: 'Partner-irodák',
  'Address Line': 'Iroda Címe',
  'Address City': 'Város',
  'Address Type': 'Fiók Típusa',
  Offices: 'Fiókok',
  OK: 'Rendben',
  'Attention!': 'Figyelem!',
  'Calculation successfully sent!': 'Kalkuláció sikeresen kiküldve!',
  'Calculation failed to send!': 'Kalkuláció kiküldése sikertelen!',
  'Accept the information sheet containing the sent calculation properly with the client, because the bank account mediation process can only start after this and this is also the condition for the commission credit!':
    'A kiküldött kalkulációt tartalmazó Tájékoztatót megfelelően fogadtasd el az ügyféllel, mert a bankszámla közvetítési folyamat csak ezt követően indulhat el és a jutalékjóváírásnak is ez a feltétele!',
  'Accept the information sheet containing the sent calculation properly with the client, because the mortgage loan mediation process can only start after this and this is also the condition for the commission credit!':
    'A kiküldött kalkulációt tartalmazó Tájékoztatót megfelelően fogadtasd el az ügyféllel, mert a jelzáloghitel közvetítési folyamat csak ezt követően indulhat el és a jutalékjóváírásnak is ez a feltétele!',
  'Accept the information sheet containing the sent calculation properly with the client, because the personal loan mediation process can only start after this and this is also the condition for the commission credit!':
    'A kiküldött kalkulációt tartalmazó Tájékoztatót megfelelően fogadtasd el az ügyféllel, mert a személyi kölcsön közvetítési folyamat csak ezt követően indulhat el és a jutalékjóváírásnak is ez a feltétele!',
  'Accept the information sheet containing the sent calculation properly with the client, because the baby loan mediation process can only start after this and this is also the condition for the commission credit!':
    'A kiküldött kalkulációt tartalmazó Tájékoztatót megfelelően fogadtasd el az ügyféllel, mert a babaváró hitel közvetítési folyamat csak ezt követően indulhat el és a jutalékjóváírásnak is ez a feltétele!',
  'Accept the information sheet containing the sent calculation properly with the client, because the mediation process can only start after this and this is also the condition for the commission credit!':
    'A kiküldött kalkulációt tartalmazó Tájékoztatót megfelelően fogadtasd el az ügyféllel, mert a közvetítési folyamat csak ezt követően indulhat el és a jutalékjóváírásnak is ez a feltétele!',
  'Are you sure you will send out the Information Sheet containing the calculation?':
    'Biztosan kiküldöd a kalkulációt tartalmazó Tájékoztatót?',
  'This involves sending the following selected calculations to the customer.':
    'Ez azzal jár, hogy az ügyfél részére a következő kiválasztott termékek kalkulációját tartalmazó Tájékoztatót kiküldjük.',
  'This means that we will send the customer an Information Sheet containing the calculation of the following selected bank account products.':
    'Ez azzal jár, hogy az ügyfél részére a következő kiválasztott bankszámla termékek kalkulációját tartalmazó Tájékoztatót kiküldjük.',
  'This means that we will send the customer an Information Sheet containing the calculation of the previously selected mortgage loan products.':
    'Ez azzal jár, hogy az ügyfél részére a korábban kiválasztott jelzáloghitel termékek kalkulációját tartalmazó Tájékoztatót kiküldjük.',
  'This means that we will send the customer an Information Sheet containing the calculation of the previously selected personal loan products.':
    'Ez azzal jár, hogy az ügyfél részére a korábban kiválasztott személyi kölcsön termékek kalkulációját tartalmazó Tájékoztatót kiküldjük.',
  'This means that we will send the customer an Information Sheet containing the calculation of the previously selected baby loan products.':
    'Ez azzal jár, hogy az ügyfél részére a korábban kiválasztott babaváró hitel termékek kalkulációját tartalmazó Tájékoztatót kiküldjük.',
  'Modify status': 'Státusz módosítás',
  'Main menu': 'Főmenü',
  'Create new list': 'Új lista létrehozása',
  'List name': 'Lista neve',
  Renaming: 'Átnevezés',
  'Show on dashboard': 'Megjelenítés az irányítópulton',
  'Remove from dashboard': 'Eltávolítás az irányítópultról',
  Copying: 'Másolás',
  'Market charts': 'Árfolyamrajzoló',
  Tools: 'Eszközök',
  'Selected persons': 'Kiválasztott személyek',
  'Not interested': 'Nem érdekel',
  'Remind me later': 'Értesíts később',
  'Featured on login': 'Cikk ajánlása belépéskor',
  'Modul under construction': 'A modul fejlesztés alatt',
  'Title not found': 'A cím nem elérhető',
  'Date not found': 'Dátum nem elérhető',
  Investments: 'Befektetések',
  Watchlists: 'Figyelő listák',
  'Show market chart': 'Árfolyamrajzolón megjelenítés',
  'You can add more assets on the right':
    'További eszközök hozzáadása a jobb oldali listából',
  'Download PDF': 'PDF letöltése',
  'Export market chart': 'Árfolyamrajzolóból nyomtatás nézet exportja',
  Graphicon: 'Grafikon',
  Attention: 'Figyelemfelhívás',
  'U cannot use the publish, when the publish schedule not empty':
    'Azonnali publikálás esetén a publikálás kezdete nem használható',
  'Add asset': 'Eszköz hozzáadása',
  'Actual changes': 'Aktuális változások',
  'Earlier changes': 'Korábbi változások',
  'Show title': 'Cím mutatása',
  'Full width': 'Teljes szélesség',
  'Has the probation period ended?': 'A próbaidő már letelt?',
  "Income's Type": 'Bevétel típusa',
  'Name of loan contracting party': 'Hitelszerződő neve',
  "Property's zip code": 'Ingatlan irányítószáma',
  'Other credits': 'Egyéb jóváírások',
  'Commits to conclusion life insurance?': 'Hitelfedezeti biztosítást köt?',
  "Application's data": 'Kérelem adatai',
  'Accepted terms and discounts': 'Vállalt feltételek és kedvezmények',
  'Account managing banks': 'Számlavezető bankok',
  'Attached documents': 'Csatolt dokumentumok',
  'Attached document': 'Csatolt dokumentum',
  'Attach document': 'Dokumentum csatolása',
  "Interest rate application's answer": 'Kamatkérés válasza',
  'Validity period': 'Érvényesség ideje',
  Remark: 'Megjegyzés',
  Reason: 'Indoklás',
  'Drag and drop here or': 'Húzza ide vagy',
  select: 'válassza ki',
  'Check calculations': 'Adatok ellenőrzése',
  'Are you sure you want to send this calculation?':
    'Biztosan elküldi a kamatkérésre a válaszát?',
  'This involves sending the following calculation to the clerk.':
    'Ez azzal jár, hogy az ügyintéző részére a következő kalkulációt kiküldjük.',
  'the document you want to upload': 'a feltölteni kívánt dokumentumot',
  "Account managing banks's tooltip information":
    'Számlavezető bankok tooltip információja',
  "Starting expense after cashback's tooltip information":
    'Induló költség visszatérítés után tooltip információja',
  "Special interest rate's tooltip information":
    'Egyedi kamat tooltip információja',
  "Validity period's tooltip information":
    'Érvényesség ideje tooltip információja',
  "Attach document's tooltip information":
    'Dokumentum csatolása tooltip információja',
  'Exclusive For Groups': 'Kötelező olvasási címkék',
  'Is Main Category': 'Fő Csoportok',
  Created_by: 'Létrehozó',
  'Critical Level': 'Kritikus mennyiség',
  'Is Critical': 'Kritikus szint alatt',
  'Product Type': 'Termék Típusa',
  'Package Price': 'Csomag ár',
  'Package Quantity': ' Csomag Mennyisége',
  'On Stock': 'Készleten',
  'Active Orders': 'Függő Rendelés',
  Popularity: 'Népszerűség',
  'Last Modified': 'Utolsó Módosítás',
  'Object Id': 'Objektum azonosítója',
  'Article Id': 'Cikk azonosítója',
  'Publish Begin': 'Publikálás kezdete',
  'Publish End': 'Publikálás vége',
  'Is Accepted': 'Elfogadta',
  Full_price: 'Rendelés össz ár',
  'Status changes': 'Státusz változások',
  'Event Name': 'Esemény neve',
  'Lowest APR per bank': 'Legalacsonyabb THM bankonként',
  'Full Price': 'Rendelés össz ár',
  'Property type': 'Ingatlan típusa',
  "Property's wall": 'Ingatlan falazata',
  'Mortgaged property value': 'Jelzáloggal terhelt ingatlan értéke',
  'Mortgaged property type': 'Jelzáloggal terhelt ingatlan típusa',
  "Mortgaged property's wall": 'Jelzáloggal terhelt ingatlan falazata',
  "Mortgaged property's zip code":
    'Jelzáloggal terhelt ingatlan  irányítószáma',
  'Individual interest cannot be given': 'Nem adható egyedi kamat',
  Submitted: 'Kiküldve',
  'Direct Leader': 'Közvetlen vezető',
  'If you want our article to appear in the Featured Articles window on the main page, check this box':
    'Ha szeretnénk, hogy a cikkünk a főoldalon lévő Fontos cikkek ablakban a Kiemelt cikkek között jelenjen meg, ikszeljük be ezt a mezőt!',
  'If you want our article to be at the top of the list of articles in one of the submenus of the left menu bar, check this box!':
    'Ha szeretnénk, hogy a cikkünk a bal oldali menüsáv, egyik almenüjében található cikklista elejére kerüljön, ikszeljük be ezt a mezőt!',
  'If you want readers to access the previous version of the article (by date), check this field when editing the article! In this way, previous versions of the article will also be traceable and readable.':
    'Ha szeretnénk, hogy az olvasók a cikk korábbi verzióját is elérjék (dátum szerint), akkor a cikk szerkesztésekor ikszeljük be ezt a mezőt! Így nyomon követhetőek és olvashatóak lesznek a cikk korábbi verziói is.',
  'If you want to recommend the article to the reader the first time they access it after publication, check this box!':
    'Ha szeretnénk ajánlani a cikket az olvasónak a publikálást követő első belépésekor, ikszeljük be ezt a mezőt!',
  Failed: 'Elutasítva',
  'The new password confirmation must match the new password entered':
    'Az új jelszó megerősítésének egyeznie kell a megadott új jelszóval',
  'Annual cost in the first year': 'Éves költség első évben',
  'Annual cost in the second year': 'Éves költség második évben',
  'Annual cost in the third year': 'Éves költség harmadik évben',
  'You do not have a valid broker licenses for the given partner':
    'Nem rendelkezel az adott partnernél érvényes banki kóddal',
  'A contract process has already been initiated for this product.':
    'Erre a termékre már van szerződés kötési folyamat indítva.',
  'A contract process has already been initiated for this calculation.':
    'Erre a kalkulációra már van szerződés kötési folyamat indítva.',
  "You don't have valid broker licenses with at least 2 different bank partners and a valid mortgage loan exam!":
    'Nincs legalább 2 különböző banki partnernél érvényes kódod és jelzáloghitel vizsgád!',
  "You don't have valid broker licenses with at least 2 different bank partners and a valid personal loan exam!":
    'Nincs legalább 2 különböző banki partnernél érvényes kódod és személyi kölcsön vizsgád!',
  "You don't have valid broker licenses with at least 2 different bank partners and a valid baby loan exam!":
    'Nincs legalább 2 különböző banki partnernél érvényes kódod és babaváró hitel vizsgád!',
  Sorting: 'Rendezés',
  'Sum of the first two years': 'Első két év összege',
  'Account holder': 'Bankszámlatulajdonos',
  'Basic fees': 'Alapdíjak',
  'Serial Number': 'Sorzatszám',
  'Destination Office': 'Átvevő iroda',
  'Courier Service': 'Futárkör',
  'Account owner': 'Számlatulajdonos',
  'Residential bank account calculator': 'Lakossági bankszámla kalkulátor',
  'Active Colleagues': 'Aktív kollégák',
  'If you want to show e.g. video in modal to the reader check this field':
    'Ha szeretnénk, hogy a ‘cikk szövege’ modalban jelenjen meg, ikszeljük be ezt a mezőt!',
  'Article content show in modal': 'Cikk tartalma modalban',
  'Cost Category': 'Költség kategória',
  'Base Conditions': 'Alap kondíciók',
  Costs: 'Költségek',
  Variants: 'Változatok',
  'Custom Rate': 'Egyedi kamat',
  'Commission Calculation Rules': 'Jutalékszabályok',
  'Value Type': 'Eredmény típusa',
  'Result Type': 'Érték Típusa',
  'ordered => process': 'Megrendelt => Feldolgozás ',
  'process => delivered': 'Feldolgozás =>  Kiszállítás',
  'Is Column Mergeable': 'Oszlop összevonás',
  'Is Row Mergeable': 'Sor összevonás',
  'Display On Comparison Sheet': 'Megjelenítés összehasonlító táblázaton',
  'Display On Product Property Table': 'Megjelenítés tulajdonságtáblán',
  'Property Order': 'Tulajdonság Sorrend',
  Properties: 'Tulajdonságok',
  'Name and address of the bank': 'Bankfiók neve, címe',
  'Clerk email address': 'Ügyintéző e-mail címe',
  'Edited By': 'Módosító ',
  'Signature Correct': 'Aláírás hiteles',
  'Target Type': 'Céltípus',
  'Has Signature': 'Aláírva',
  'Copy to clipboard': 'Vágólapra másol',
  'Clipboard copy was success': 'Sikeresen vágolapra másolva',
  'Copy to clipboard failed, try another way':
    'Vágolapra másolás hiba, próbálja máshogy',
  'Important! This message only appears once Save your API-KEY in a safety place!':
    'Fontos! Ez az üzenet csak egyszer jelenik meg, mentsd el az API-KEY - t biztonságos helyre.',
  'I saved the API-KEY': 'Elmentettem az API-KEY - t',
  'Cost Nodes': 'Költségfa',
  'Node Result': 'Elem értéke',
  'Included In Thm': 'THM része',
  Parent: 'Szülő',
  'Is Initial Cost': 'Kezdeti költség',
  'Broker User': 'Üzletkötő',
  'Ld Leader': 'Országos Igazgató',
  'Complaint Category': 'Panasz Kategória',
  'Complaint Subject': 'Panasz tárgya',
  'Answer Type': 'Válasz típusa',
  'Submission Time': 'Benyűjtás időpontja',
  'Complaint Submit Mode': 'Benyújtás módja',
  Watchers: 'Feliratkozók',
  'Template Type': 'Sablon Típusa',
  'Check Needed': 'Ellenőrzés szükséges',
  'Complaint Answered Time': 'Megválaszolás időpontja',
  'User View Count': ' Felhasználói megtekintések száma',
  'Sum View Count': 'Összes megtekintés',
  'Complaint datas': 'Panasz adatok',
  'There are no comments yet': 'Még nincsenek hozzászólások',
  'There are no additional fields yet': 'Még nincsenek kiegészítő mezők',
  'Additional fields': 'Kiegészítő mezők',
  'HUF/Quantity': 'Ft/db',
  Piece: 'db',
  'Property Category': 'Tulajdonság kategória',
  'Filling in the SMS CODE is mandatory': 'Az SMS KÓD kitöltése kötelező',
  'To proceed further, please accept the following declarations and the Information Sheet!':
    'A továbblépéshez kérjük, fogadja el az alábbi nyilatkozatokat és a Tájékoztatót!',
  'To proceed further, please accept the above declarations and enter the code received in the SMS!':
    'A továbblépéshez kérjük fogadja el a fenti nyilatkozatokat és adja meg az sms - ben kapott kódot!',
  'Acceptance of the statement is mandatory':
    'A nyilatkozat elfogadása kötelező',
  record: 'rekord',
  'Product inventory': 'Termékkészlet',
  'Commission Payment Min Year': 'Jutalék fizetés minimum év',
  'Commission Payment Max Year': 'Jutalék fizetés maximum év',
  'Minimum Product Term': 'Minimum termék futamidő',
  'Maximum Product Term': 'Maximum termék futamidő',
  'Start time': 'Kezdés',
  'End time': 'Befejezés',
  MainCategory: 'Főkategória',
  'Partner Type': 'Partner típusa',
  Complaints: 'Panaszok',
  'Product Inventory': 'Termék leltár',
  'Fulfilled Count': 'Kiszállított mennyiség',
  'Ordered Quantity': 'Rendelt mennyiség',
  Calculations: 'Kalkulációk',
  'Product features': 'Terméktulajdonságok',
  count: 'Mennyiség',
  'First close average': 'Átlagos megoldási idő',
  'First reaction average': 'Átlagos reakcióidő',
  hours: 'óra',
  'Select new category': 'Válassz új kategóriát',
  pieces: 'darab',
  'Category statistics cannot be loaded':
    'A kategória statisztikái nem tölthetőek be',
  'Category data cannot be loaded': 'Kategória adatok nem tölthetőek be',
  'Parsing Type': 'Feldolgozás módja',
  'User Group': 'Felhasználói csoport',
  'Has Multiple Participants': 'Több azonos rangú résztvevő',
  'Has Performance Evaluation': 'Teljesítmény-kontrollban számít',
  Abbreviation: 'Rövidítés',
  'Color Saturation': 'Szín telítettsége',
  'Type Color': 'Típus színe',
  'Send Notification': 'Értesítés küldése',
  Organizer: 'Szervező',
  'Is Whole Day': 'Egész napos',
  'Is Recurring': 'Ismétlődő',
  'End Recurring Period': 'Ismétlődés vége',
  'End Date': 'Záró dátum',
  Unknown: 'Ismeretlen',
  'Hide more calculations': 'További kalkulációkat rejt',
  'Show more calculations': 'További kalkulációkat mutat',
  Role: 'Szerepkör',
  Event: 'Esemény',
  'Under classification': 'Besorolás alatt',
  'Asset Type': 'Eszköz típusa',
  Assets: 'Eszközök',
  Cost: 'Költség',
  'Is Backtest': 'Indulás előtti árfolyam',
  Asset: 'Eszköz',
  Participant: 'Résztvevő',
  'Google place is not set': 'Google Place helyszín nincs beállítva',

  'Search Categories': 'Kategória keresés',
  Notifications: 'Értesítések',
  'Basic infos': 'Alapadatok',
  'Company infos': 'Cégadatok',
  Structure: 'Struktúra',
  'Acceptance log': 'Elfogadás napló',
  'Leader structure': 'Vezetői struktúra',
  'Recruitment structure': 'Beszervezői struktúra',
  'Parallel structure': 'Párhuzamos struktúra',
  'Data is not correct': 'Az adat nem megfelelő',
  'Specifying the name of the watchlist is mandatory':
    'A figyelőlista nevének megadása kötelező',
  'Jump direct to asset': 'direkt ugrás eszköz blabla',
  'Watchlist name': 'Figyelőlista neve',
  'Create new watchlist': 'Új figyelőlista létrehozása',
  'Rename watchlist': 'Figyelőlista átnevezése',
  rename: 'átnevezés',
  'Watchlist new name': 'Figyelőlista új neve',
  'Delete watchlist': 'Figyelőlista törlése',
  'Are you sure delete this:': 'Biztosan törölni szeretné :',
  'Copy Watchlist': 'Figyelőlista másolása',
  'copy to new name': 'másolása új név alá',
  'Datastructure  cannot be displayed': 'Adatszerkezet nem jeleníthető meg',
  'Last State': 'Utolsó állapot',
  'Participate In Calculation': 'Kalkulációban részt vesz',
  'Select Template block': 'Válassz sablon blokkot',
  'Template block selector': 'Sablon blokk választó',
  'Template block preview': 'Sablon blokk előnézet',
  'Select Dynamic zone': 'Válassz dinamikus zónát',
  'Dynamic zone selector': 'Dinamikus zóna választó',
  'Dynamic zone preview': 'Dinamikus zóna előnézet',
  'Extra properties': 'Extra tulajdonságok',
  'Post Action': 'Beküldés utáni művelet',
  Form: 'Űrlap',
  'Notification Type': 'Értesítés típusa',
  'Broker Notification Type': 'Üzletkötő értesítés típusa',
  'Client Notification Type': 'Ügyfél értesítés típusa',
  'Created At': 'Létrehozva',
  Metadata: 'Meta adatok',
  'Phone Number': 'Telefonszám',
  'Post Action Executed At': 'Biztosítónak beküldve',
  'Remote Id': 'Külső azonosító',
  'Signed At': 'Biztosított által megerősítve',
  'Submitted At': 'Biztosított által kitöltve',
  'Sign Notification Type': 'Aláírás értesítés típusa',
  'Published At': 'Publikálva',
  'Author Name': 'Szerző neve',
  Text: 'Szöveg',
  Rating: 'Értékelés',
  'Assign to user and client': 'Felhasználó és ügyfél hozzárendelése',
  'Assign to user': 'Felhasználó hozzárendelése',
  'Assign to client': 'Ügyfél hozzárendelése',
  'Unable to assign': 'Nem lehet hozzárendelni',
  Unassign: 'Hozzárendelés visszavonása',
  'Sent At': 'Ügyfélnek kiküldve',
  'Sent Retry Count': 'Újraküldés száma',
  'Statement notification limit exceeded.':
    'Túllépte az újraküldési kísérletek számát! (Max. 3)',
  'Success resend!': 'Sikeres újra küldés!',
  Revoked: 'Visszavont',
  'Is Obligatory': 'Kötelező',
  'The table is empty or there are no matching results for the filter criteria':
    'A tábla üres vagy nincs megfelelő találat a szűrési feltételekre',
  'Last Login': 'Utolsó bejelentkezés',
  'Statistics as an employee': 'Munkatársakénti statisztika',
  'Office statistics': 'Iroda statisztika',
  'Most recently classified': 'Legutóbb besorolva',
  'Author profile uri': 'Szerző profil url-je',
  'Regional Director': 'Régió igazgatója',
  'Country Director': 'Országos igazgatója',
  'Previous Quarter Review Count': 'Előző negyedév értékelések darabszáma',
  'Previous Quarter Review Count': 'Előző negyedév értékelések darabszáma',
  'Current Quarter Review Count': 'Aktuális negyedév értékelések darabszáma',
  'Review Avg Rating': 'Értékelések átlaga',
  'Review Count': 'Értékelés darabszáma',
  'Full Name': 'Teljes név',
  'Per Colleagues': 'Munkatársanként',
  'Per Offices': 'Irodánként',
  'Selected date range': 'Kiválasztott dátum intervallum',
  'Set tags': 'Címkék beállítása',
  'Set related user': 'Kapcsolódó felhasználó hozzárendelése',
  'Confirm Assignment': 'Hozzárendelés megerősítése',
  'Auto assigned': 'Automatikusan összerendelve',
  'The code has been copied to the clipboard.': 'A kód vágólapra másolva.',
  'Code is required for embedding': 'Beágyazáshoz szükséges kód',
  'Please select a(n) user to display a code.':
    'Kód megjelenítéséhez kérjük válasszon felhasználót.',
  'Only marked in office reviews': 'Csak az irodai értékelésekben megjelöltek',
  'Please select a(n) office to display a code.':
    'Kód megjelenítéséhez kérjük válasszon egy irodát.',
  'Center Align': 'Középre igazítás',
  Transitions: 'Átmenetek',
  'Conversation Recipient': 'Megszólított',
  'Owner Group': 'tulajdonos csoport',
  'Reference User': 'Referencia felhasználó',
  'Target User Group': 'Célcsoport',
  'Conversation Category': 'Beszélgetés kategória',
  'Conversation Type': 'Beszélgetés típusa',
  'Verified By': 'Megerősítve',
  'Verified At': 'Megerősítés ideje',
  'Commitment Name': 'Vállalás neve',
  'Create Commitment': 'Vállalás létrehozása',
  'Undo Commitment': 'Vállalás visszavonása',
  'Set Deadline': 'Határidő beállítása',
  'Ready To File': 'Iktatásra kész',
  'Ready For Unit': 'Egységérett',
  'Is Online': 'Online',
  Own: 'Saját',
  'Contract process': 'Kötési folyamat',
  'Team processes': 'Munkatársaim folyamatai',
  "My specialist's processes": 'Specialistáim folyamatai',
  'Excel file': 'Excel fájl',
  'Contract process already exists, status will be updated.':
    'A kötési folyamat már létezik, a státusz frissítve lesz.',
  'The received value `{name}` is not configured. Add the missing configuration in the import settings or delete the entry.':
    'A kapott érték `{name}` nincs konfigurálva. Add hozzá a hiányzó konfigurációt az import beállításokban, vagy töröld a bejegyzést.',
  'The received value `{name}` is not configured. The contract process will be stored without a product.':
    'A kapott érték `{name}` nincs konfigurálva. A kötési folyamat termék nélkül kerül mentésre.',
  'This field is empty! The contract process will be stored without a product.':
    'Ez a mező üres! A kötési folyamat termék nélkül kerül mentésre.',
  'Imported data': 'Importált adat',
  'The requested status change is not allowed from imported data. The status will not be updated.':
    'A kért státuszváltozás nem engedélyezett importált adatokból. A státusz nem lesz frissítve.',
  'Create new contract process': 'Új kötési folyamat létrehozása',
  'The contract process will be created from the imported data.':
    'A kötési folyamat létre lesz hozva az importált adatokból.',
  'No changes detected.': 'Nincs változás.',
  'Lead provider': 'Lead adó',
  'Lead Provider': 'Lead Adó',
  'Select a client first!': 'Először válassz ügyfelet!',
  'No lead provider': 'Nincs lead adó',
  'No product': 'Nincs termék',
  'No broker': 'Nincs üzletkötő',
  'Skip broker': 'Üzletkötő kihagyása',
  'The contract process will be stored without a broker.':
    'A kötési folyamat üzletkötő nélkül kerül mentésre.',
  'No user level': 'Nincs felhasználói rang',
  'This is the broker associated with the selected contract process.':
    'Az üzletkötő a kötési folyamathoz tartozik.',
  'The contract process does not have a lead provider.':
    'A kötési folyamatnak nincs lead adója.',
  'Contract Remote Id': 'Szerződés külső azonosítója',
  'The broker has a better match by name in the imported data. Check the broker update the entry if necessary.':
    'Az üzletkötőre van jobb találat név szerint az importált adatokban. Ellenőrizd az üzletkötőt és frissítsd a bejegyzést ha szükséges.',
  Filename: 'Fájlnév',
  'Started by me': 'Általam indított',
  'Related to my clients': 'Ügyfeleimhez tartozó',
  'Related to my leads': 'Általam adott leadekhez tartozó',
  'Status Updated At': 'Státusz frissítve',
  'Contract Date': 'Szerződéskötés dátuma',
  Selection: 'Kiválasztás',
  'Please select.': 'Kérjük válasszon.',
  Manager: 'Kezelő',
  'Clients Manager': 'Ügyfél kezelője',
  'Manager Role': 'Kezelő szerepe',
  'Method of contracting process': 'Szerződéskötési folyamat módja',
  'Bank form': 'Banki űrlap',
  'Completing the bank form is required.': 'A banki űrlap kitöltése kötelező.',
  'Failed to load data. Please try again later.':
    'Nem sikerült betölteni az adatokat. Kérlek, próbáld újra később.',
  'Own contract': 'Saját kötés',
  Specialist: 'Specialista',
  'Related User': 'Kapcsolódó felhasználó',
  'Information on the portal': 'Információ a portálon',
  'Before starting the online account opening, fill in the intermediary':
    'Az online számlanyitás megkezdése előtt töltsd ki a közvetítői',
  'If the bank account is opened in a bank branch after a tip, the':
    'Amennyiben banki lead adást követően kerül sor a fióki számlanyitásra, az',
  form: 'űrlap',
  'filling out is mandatory': 'kitöltése kötelező',
  'bank form': 'banki űrlapot',
  'Review With Text Count': 'Szöveges értékelések darabszáma',
  'Google Reviews Enabled': 'Google értékelés bekapcsolva',
  'Performing calculation': 'Kalkuláció elvégzése',
  'Processing in progress': 'Feldolgozás folyamatban',
  'Be patient, this may take a few seconds.':
    'Türelem, ez eltarthat pár másodpercig.',
  'Results per pagination': 'Találatok oldalanként',
  'To open the Information Sheet, you need to enter your date of birth.':
    'A Tájékoztató megnyitásához születési dátumának megadására van szükség.',
  'Default Channels': 'Alapértelmezett csatornák',
  'PDF download': 'Tájékoztató letöltése',
  Pending: 'Függőben',
  Success: 'Sikeres',
  'Done with errors': 'Kész, de vannak hibák',
  Canceled: 'Sikertelenül lezárva',
  'Calculation Note': 'Kalkuláció megjegyzés',
  'Calculation Loan Amount': 'Kalkuláció hitelösszeg',
  'Existing Sifi process': 'Meglévő Sifi folyamat',
  'New Sifi process': 'Új Sifi folyamat',
  'Selected person': 'Kiválasztott személy'
}

export default hu
