function truncate(input, length = 10) {
  if (!input) return input

  if (input.length > length) {
    return input.substring(0, length) + '…'
  }
  return input
}

export default truncate
