const hu = {
  'Sign Notification Type': 'Aláírás értesítés típusa',
  Appearance: 'Kinézet',
  Datas: 'Adatok',
  'Image processing, background removal in progress':
    'Kép feldolgozása, háttér eltávolítása folyamatban',
  'Image has been processed successfully':
    'Kép feldolgozása sikeresen megtörtént',
  'Image processing failed': 'Kép feldolgozása sikerertelen',
  'Unsupported file format, only image file is supported':
    'Nem támogatott fájlformátum, csak képfájl támogatott',
  'The file size is too large, the maximum file size is 5 MB':
    'Túl nagy a fájl mérete, a maximális fájlméret: 5 MB',
  'Business card': 'Névjegykártya',
  Company: 'Cég',
  Informations: 'Információk',
  Services: 'Szolgáltatások',
  'Social media': 'Közösségi média',
  'Base color': 'Alapszín',
  'Profile image': 'Profilkép',
  'Profile picture': 'Profilkép',
  'Cropping the image failed! Try reducing the image size. Supported formats: JPG, PNG.':
    'A kép körülvágása nem sikerült! Próbáld meg csökkenteni a kép méretét, illetve a támogatott formátumok: JPG, PNG.',
  'Background color': 'Háttérszín',
  'Background image': 'Háttérkép',
  'Background enabled': 'Legyen háttér',
  'Name of rank': 'Rang megnevezése',
  'Displayed name': 'Megjelenített név',
  'Selected specialization': 'Specialista fajtája',
  Mobile: 'Mobil',
  'Show e-mail button': 'E-mail gomb megjelenítése',
  'Show phone button': 'Hívás gomb megjelenítése',
  'Google reviews enabled': 'Google értékelés bekapcsolása',
  Office: 'Iroda',
  'OVB website': 'OVB hivatalos weboldala',
  'OVB facebook': 'OVB Magyarország Facebook oldala',
  'OVB customer information': 'OVB Ügyféltájékoztató',
  'Financial and insurance solutions': 'Pénzügyi és biztosítási megoldások',
  'Retirement savings': 'Nyugdíj-előtakarékosság',
  "Children's future": 'Gyermekjövő',
  'Wealth creation': 'Vagyon felépítése',
  'Credit products, state subsidies': 'Hiteltermékek, állami támogatások',
  'Tax benefits and refunds': 'Adókedvezmények, -visszatérítések',
  'Safety in every life situation': 'Biztonság bármely élethelyzetben',
  'Asset protection': 'Vagyonvédelem',
  'SME solutions': 'KKV megoldások',
  'Online car insurance': 'Online gépjármű biztosítás',
  'Online car assistance': 'Online gépjármű asszisztencia',
  'Online travel insurance': 'Online utasbiztosítás',
  'Join us as a consultant': 'Csatlakozzon hozzánk tanácsadóként',
  'WhatsApp phone number': 'WhatsApp telefonszám',
  'Viber phone number': 'Viber telefonszám',
  'Required to fill out.': 'Kötelező kitölteni.',
  Heading: 'Cím',
  'The office contact information': 'Iroda elérhetősége',
  Call: 'Hívás',
  'Central employee - KP': 'Központi munkatárs - KP',
  'The uploaded and cut photo must correspond in every way the conditions below':
    'A feltöltött és vágott fénykép feleljen meg az alábbi feltételeknek',
  'Wear business attire in the photo': 'A fényképen üzleti öltözetet viselj',
  'The background of the photo should be homogeneous (preferably dark gray and not light gray)':
    'A fénykép háttere homogén legyen (lehetőleg sötétszürke, és nem világosszürke)',
  'Look straight into the camera in the picture':
    'A fényképen egyenesen a kamerába nézzél',
  'The head should be the main part of the photo, leaving some space above the head':
    'A fénykép nagy részét a fejed foglalja el, felette pedig csak kis hely maradon.',
  'Upload and crop the image based on the examples below.':
    'Az alábbi példák alapján töltsd fel és vágd meg a képet.',
  'Copy link': 'Link másolás',
  'Digital business card': 'Digitális névjegy',
  'The link has been copied to the clipboard.':
    'A link másolva lett a vágólapra.',
  'Written by my clients': 'Ügyfeleim írták',
  'Show more': 'Bővebben',
  'Show less': 'Kevesebbet',
  Mobile: 'Mobil',
  'Generate mini business card': 'Mini névjegy generálása',
  'Download image': 'Kép letöltése',
  'Rating is visible': 'Értékelés látszik',
  'No rating yet': 'Nincs értékelés még',
  'The image has been downloaded successfully.': 'A kép letöltése sikeres.',
  'Error downloading image.': 'Hiba a kép letöltése közben',
  point: 'pont'
}

export default hu
