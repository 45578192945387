import { Box, Typography } from '@mui/material'
import EvaluationCard from './EvaluationCard'
import EvaluationCardSliderColumns from './EvaluationCardSliderColumns'
import { useTranslation } from 'react-i18next'
import i18next from './../../../../src/i18n'
import hu from '../i18n/i18n-hu'
import hexToRgbaWithOpacity from '../../core/utils/hexToRgbaWithOpacity'

function GoogleReview(props) {
  const {
    evaluations,
    baseColorHex,
    includeTitle = true,
    sliderColumns = false
  } = props
  const { t } = useTranslation()
  i18next.addResourceBundle('hu', 'translation', hu)

  const backgroundColor = baseColorHex
    ? hexToRgbaWithOpacity(baseColorHex.hex, 0.1)
    : '#CC00661a'

  return (
    <Box sx={{ minWidth: '288px' }}>
      {includeTitle && (
        <Typography
          sx={{
            marginBottom: '.5rem',
            fontWeight: 600,
            fontSize: '16px',
            textAlign: 'left'
          }}
        >
          {t('Written by my clients')}
        </Typography>
      )}
      {sliderColumns ? (
        <EvaluationCardSliderColumns
          evaluations={evaluations}
          baseColorHex={baseColorHex}
          backgroundColor={backgroundColor}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: { backgroundColor },
            borderRadius: '8px',
            minHeight: '269px'
          }}
        >
          <EvaluationCard
            evaluations={evaluations}
            baseColorHex={baseColorHex}
          />
        </Box>
      )}
    </Box>
  )
}

export default GoogleReview
