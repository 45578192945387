import GoogleReview from './GoogleReview'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'

const GoogleReviewWCWrapper = (props) => {
  const {
    office = '',
    includeoffice = false,
    userovbid = '',
    userid = '',
    includetitle = 'true',
    basecolorhex,
    slidercolumns = 'false'
  } = props
  const [loading, setLoading] = useState()
  const [data, setData] = useState()

  const baseColorHex = { hex: basecolorhex || '#CC00661a' }
  const includeTitle = includetitle === 'true'
  const sliderColumns = slidercolumns === 'true'

  let queryParams = `?userIncludedInOfficeReviews=${includeoffice}`
  if (office !== '') {
    queryParams += `&officeId=${office}`
  }
  if (userid !== '') {
    queryParams += `&userId=${userid}`
  } else if (userovbid !== '') {
    queryParams += `&userOvbId=${userovbid}`
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const response = await fetch(
          `${__PUBLIC_FRONTEND_V2_API_URL__}/reviews/reviews${queryParams}`,
          { credentials: 'include' }
        )
        const data = await response.json()

        setData(data)
      } catch (error) {
        console.error('Error fetching reviews data:', error)
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {data?.results && (
            <GoogleReview
              baseColorHex={baseColorHex}
              evaluations={data?.results}
              includeTitle={includeTitle}
              sliderColumns={sliderColumns}
            />
          )}
        </>
      )}
    </>
  )
}
export default GoogleReviewWCWrapper
