import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Box } from '@mui/material'

import RatingStars from './RatingStars'
import Typography from '@mui/material/Typography'
import i18next from './../../../../src/i18n'
import hu from '../i18n/i18n-hu'
import hexToRgbaWithOpacity from '../../core/utils/hexToRgbaWithOpacity'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

function ArrowBtn(props) {
  const { className, style, onClick, icon: Icon, color } = props
  return (
    <Icon
      className={className}
      style={{
        ...style,
        display: 'block',
        color: color
      }}
      onClick={onClick}
    />
  )
}

const EvaluationCardSliderColumns = (props) => {
  const { evaluations, baseColorHex, backgroundColor } = props
  i18next.addResourceBundle('hu', 'translation', hu)

  const color = baseColorHex
    ? hexToRgbaWithOpacity(baseColorHex.hex, 0.8)
    : '#cc0066'

  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowBtn icon={ArrowForwardIosIcon} color={color} />,
    prevArrow: <ArrowBtn icon={ArrowBackIosNewIcon} color={color} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Box>
      <Slider {...settings}>
        {evaluations.map((data, index) => {
          const date = new Date(data.publishedAt)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          const formattedDate = `${year}.${month < 10 ? '0' + month : month}.${
            day < 10 ? '0' + day : day
          }.`

          return (
            <Box key={`evaluation-'${index}'`}>
              <Box
                sx={{
                  backgroundColor: { backgroundColor },
                  p: '16px',
                  mr: '16px',
                  height: '193px',
                  mt: '16px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontFamily: 'Montserrat, sans-serif'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '21.98px'
                    }}
                  >
                    {data?.authorName}
                  </Typography>
                  <img
                    src="https://cdn.ovbportal.hu/public/logos/google_logo.svg"
                    alt="Google Logo"
                    style={{
                      height: '20px'
                    }}
                  />
                </Box>
                <RatingStars rating={data?.rating} />

                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#71777D',
                    lineHeight: '19.92px',
                    marginBottom: '5px',
                    textAlign: 'left'
                  }}
                >
                  {formattedDate}
                </Typography>
                <Box
                  sx={{
                    lineHeight: '21.98px',
                    color: '#313B45',
                    fontWeight: 400,
                    fontSize: '14px',
                    marginBottom: '5px',
                    textAlign: 'left',
                    overflowY: 'auto',
                    maxHeight: '70px',
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: color
                    }
                  }}
                >
                  {data.text}
                </Box>
              </Box>
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}

export default EvaluationCardSliderColumns
