import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import hu from './i18n/i18n-hu.js'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'hu', // default language
    debug: false,
    fallbackLng: 'en',
    resources: {
      hu: {
        translation: {
          ...hu
        }
      }
    }
  })

export default i18next
