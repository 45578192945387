import truncate from 'ovbportal-frontend/src/core/utils/truncate'
import Carousel from 'react-material-ui-carousel'
import RatingStars from './RatingStars'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from './../../../../src/i18n'
import hu from '../i18n/i18n-hu'
import hexToRgbaWithOpacity from '../../core/utils/hexToRgbaWithOpacity'

const EvaluationCard = (props) => {
  const { evaluations, baseColorHex } = props
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  i18next.addResourceBundle('hu', 'translation', hu)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const color = baseColorHex
    ? hexToRgbaWithOpacity(baseColorHex.hex, 0.8)
    : '#cc0066'

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingBottom: '16px'
      }}
    >
      <Carousel
        autoPlay={false}
        stopAutoPlayOnHover={false}
        animation="slide"
        interval="5000"
        duration="500"
        swipe={true}
        fullHeightHover={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        navButtonsWrapperProps={{
          style: {
            top: '75px',
            color: color,
            paddingLeft: '4px',
            paddingRight: '4px'
          }
        }}
        navButtonsProps={{
          style: {
            margin: 0,
            marginTop: '0px',
            padding: '0px',
            color: color,
            background: 'transparent'
          }
        }}
        indicatorIconButtonProps={{
          style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            color: '#DCDDDF'
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: color
          }
        }}
      >
        {evaluations.map((data, index) => {
          const date = new Date(data.publishedAt)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          const formattedDate = `${year}.${month < 10 ? '0' + month : month}.${
            day < 10 ? '0' + day : day
          }.`

          return (
            <Box
              key={`evaluation-'${index}'`}
              sx={{ paddingLeft: '32px', paddingRight: '32px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontFamily: 'Montserrat, sans-serif'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '21.98px'
                  }}
                >
                  {data?.authorName}
                </Typography>
                <img
                  src="https://cdn.ovbportal.hu/public/logos/google_logo.svg"
                  alt="Google Logo"
                  style={{
                    height: '20px'
                    //cursor: 'pointer'
                  }}
                  // onClick={() => handleGoogleClick(data.googleLink)}
                />
              </Box>
              <RatingStars rating={data?.rating} />

              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#71777D',
                  lineHeight: '19.92px',
                  marginBottom: '5px',
                  textAlign: 'left'
                }}
              >
                {formattedDate}
              </Typography>
              <Box
                sx={{
                  lineHeight: '21.98px',
                  color: '#313B45',
                  fontWeight: 400,
                  fontSize: '14px',
                  marginBottom: '5px',
                  textAlign: 'left',
                  overflowY: 'auto',
                  minHeight: '100px',
                  maxHeight: expanded ? '134px' : 'auto',
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: color
                  }
                }}
              >
                {expanded ? data.text : truncate(data.text, 200)}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {data.text?.length > 200 && (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      cursor: 'pointer',
                      margin: '5px auto 5px',
                      color: color
                    }}
                    onClick={() => handleExpandClick()}
                  >
                    {expanded ? t('Show less') : t('Show more')}
                  </Typography>
                )}
              </Box>
            </Box>
          )
        })}
      </Carousel>
    </Box>
  )
}

export default EvaluationCard
