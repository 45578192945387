import Box from '@mui/material/Box'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'

function RatingStars(props) {
  const { rating } = props

  const ratingStars = Array(rating)
    .fill(true)
    .concat(Array(5 - rating).fill(false))

  return (
    <Box
      sx={{
        marginTop: '5px',
        display: 'flex',
        color: '#FFB400',
        fontSize: '15px'
      }}
    >
      {ratingStars.map((fullStar, i) => (
        <Box key={`nctistr${i}`}>
          {fullStar ? <StarIcon /> : <StarBorderIcon />}
        </Box>
      ))}
    </Box>
  )
}

export default RatingStars
